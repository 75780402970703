import {
  U21Avatar,
  U21Chip,
  U21ChipProps,
  U21MenuLayout,
  U21MenuList,
  U21MenuListOptionProps,
} from 'app/shared/u21-ui/components';
import { useReassignCase } from 'app/modules/cases/queries/useReassignCase';
import { useGetCase } from 'app/modules/cases/queries/useGetCase';
import { useDispatch, useSelector } from 'react-redux';
import { selectHasReassignCasePermission } from 'app/modules/cases/selectors';
import { IconFlagArrow } from 'app/shared/components/Icons/IconFlagArrow';
import { useMemo, useState } from 'react';
import { useSearchAgents } from 'app/modules/agents/queries/useSearchAgents';
import { getAgentDisplayName } from 'app/modules/agents/utils';
import { AgentStatus } from 'app/modules/agents/models';
import {
  DEFAULT_OFFSET,
  DEFAULT_PAGINATION_LIMIT,
} from 'app/shared/pagination/constants';
import { useGetAgent } from 'app/modules/agents/queries/useGetAgent';
import { get, post } from 'app/shared/utils/fetchr';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { updateCaseCache } from 'app/modules/casesOld/actions';
import { updateCasesCache } from 'app/modules/cases/sliceCases';
import { selectDLEnabledForCaseDetail } from 'app/shared/featureFlags/selectors';

interface Props {
  id: number;
}

export const Reassign = (props: Props) => {
  const { id } = props;

  const dispatch = useDispatch();
  const hasReassignCasePermission = useSelector(
    selectHasReassignCasePermission,
  );

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const { case: c } = useGetCase(id);
  const { agents, isLoading: agentsLoading } = useSearchAgents({
    limit: DEFAULT_PAGINATION_LIMIT,
    offset: DEFAULT_OFFSET,
    query: search,
    statuses: [AgentStatus.ACTIVE],
  });
  const { mutateAsync: reassignCase } = useReassignCase([id]);
  const useNewEndpoint = useSelector(selectDLEnabledForCaseDetail);
  const { data: agent } = useGetAgent(c?.assigned_to_id);

  const options = useMemo<U21MenuListOptionProps[]>(() => {
    // retrieve latest case info to update the cached redux data w/ new assignee
    // unfortunately the investigation/action API does not return the latest data
    // so need to manually trigger another API to get it
    // this will no longer be needed once the case table uses hooks
    const onActionSuccess = async () => {
      const response = useNewEndpoint
        ? await get<InvestigationsCaseResponse<false>>(`/cases/cached/${id}`)
        : await post<InvestigationsCaseResponse<false>>(
            '/investigations/retrieve/case',
            {
              short: false,
              object_type: 'CASE',
              object_ids: [id],
            } satisfies InvestigationsPayload,
          );
      if (response.cases[0]) {
        dispatch(updateCaseCache(response.cases[0]));
        dispatch(updateCasesCache(response.cases[0]));
      }
    };

    return [
      {
        icon: <U21Avatar name="?" />,
        text: 'Unassigned',
        onClick: async (e) => {
          e.stopPropagation();
          try {
            await reassignCase(-1);
            await onActionSuccess();
          } catch {}
          setOpen(false);
        },
      },
      ...agents.map((i) => {
        const name = getAgentDisplayName(i);
        return {
          text: name,
          onClick: async (e) => {
            e.stopPropagation();
            setOpen(false);
            try {
              await reassignCase(i.id);
              await onActionSuccess();
            } catch {}
          },
          icon: <U21Avatar name={name} src={i.picture} />,
        };
      }),
    ];
  }, [agents, dispatch, id, reassignCase, useNewEndpoint]);

  const avatar = useMemo(() => {
    if (agent) {
      return (
        <U21Avatar
          name={getAgentDisplayName(agent)}
          size="small"
          src={agent.picture}
        />
      );
    }
    if (c?.assigned_to_id) {
      const found = agents.find((i) => i.id === c.assigned_to_id);
      if (found) {
        return (
          <U21Avatar
            name={getAgentDisplayName(found)}
            size="small"
            src={found.picture}
          />
        );
      }
    }
    return <IconFlagArrow />;
  }, [agent, agents, c?.assigned_to_id]);

  if (!c) {
    return null;
  }

  const chipProps: U21ChipProps = {
    children: c.assigned_to_name || 'Unassigned',
    color: 'primary',
    icon: avatar,
    variant: 'ghost',
  };

  if (
    !hasReassignCasePermission ||
    c.status === 'CLOSED' ||
    c.status === 'IN_REVIEW'
  ) {
    return <U21Chip {...chipProps} />;
  }

  return (
    <U21MenuLayout
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
      trigger={<U21Chip {...chipProps} onClick={() => setOpen(true)} />}
      open={open}
    >
      <U21MenuList
        loading={agentsLoading}
        options={options}
        placeholder="Search agents"
        onSearch={setSearch}
      />
    </U21MenuLayout>
  );
};

import { AiInvestigationParagraphWithSource } from 'app/modules/investigations/models';
import {
  AiInvestigationResultReference,
  AiInvestigationExternalSource,
  AiInvestigationInternalSource,
  AiInvestigationResult,
} from 'app/modules/investigations/types/responses';

const getSentenceSourceNumber = (
  references: AiInvestigationResultReference[],
): {
  lineToSourceNumberMap: Record<string, number[]>;
  sourceNumberToExternalSourceMap: Map<number, AiInvestigationExternalSource>;
  sourceNumberToInternalSourceMap: Map<number, AiInvestigationInternalSource>;
} => {
  const lineToSourceMap: Record<string, string[]> = {};
  const sourceToExternalSourceMap: Map<string, AiInvestigationExternalSource> =
    new Map();
  const sourceToInternalSourceMap: Map<string, AiInvestigationInternalSource> =
    new Map();

  // Deduplicating sources and splitting it by internal and internet sources
  // so that internal sources can be above internet sources
  references.forEach(({ content_line: contentLine, sources }) => {
    if (lineToSourceMap[contentLine] === undefined) {
      lineToSourceMap[contentLine] = [];
    }

    if (sources) {
      sources.forEach((source) => {
        const sourceType = source.source_type;

        if (sourceType === 'Internet') {
          if (source.links) {
            source.links.forEach((externalSource) => {
              lineToSourceMap[contentLine].push(externalSource.url);
              sourceToExternalSourceMap.set(externalSource.url, externalSource);
            });
          }
        } else if (sourceType === 'Internal') {
          if (source.data_ref) {
            source.data_ref.forEach((internalSource) => {
              const internalSourceKey = `${internalSource.data_type}-${internalSource.id}`;
              lineToSourceMap[contentLine].push(internalSourceKey);
              sourceToInternalSourceMap.set(internalSourceKey, internalSource);
            });
          }
        }
      });
    }
  });

  // Assigning source numbers to each source, starting from 1
  const keyToSourceNumberMap: Record<string, number> = {};
  let sourceNumber = 1;

  sourceToInternalSourceMap.forEach((_, dataRefKey) => {
    keyToSourceNumberMap[dataRefKey] = sourceNumber;
    sourceNumber += 1;
  });

  sourceToExternalSourceMap.forEach((_, linkKey) => {
    keyToSourceNumberMap[linkKey] = sourceNumber;
    sourceNumber += 1;
  });

  // Remapping line to source number
  const lineToSourceNumberMap: Record<string, number[]> = Object.entries(
    lineToSourceMap,
  ).reduce((acc, [line, sources]) => {
    if (sources.length === 0) {
      return acc;
    }

    acc[line] = sources.map((source) => keyToSourceNumberMap[source]);
    return acc;
  }, {});

  // Remapping source number to data ref
  const sourceNumberToInternalSourceMap: Map<
    number,
    AiInvestigationInternalSource
  > = Array.from(sourceToInternalSourceMap.entries()).reduce(
    (acc, [dataRefKey, dataRef]) => {
      acc.set(keyToSourceNumberMap[dataRefKey], dataRef);
      return acc;
    },
    new Map<number, AiInvestigationInternalSource>(),
  );

  // Remapping source number to link
  const sourceNumberToExternalSourceMap: Map<
    number,
    AiInvestigationExternalSource
  > = Array.from(sourceToExternalSourceMap.entries()).reduce(
    (acc, [url, externalSource]) => {
      acc.set(keyToSourceNumberMap[url], externalSource);
      return acc;
    },
    new Map<number, AiInvestigationExternalSource>(),
  );

  return {
    lineToSourceNumberMap,
    sourceNumberToExternalSourceMap,
    sourceNumberToInternalSourceMap,
  };
};

const getParagraphWithSource = (
  paragraph: string,
  lineToSourceNumberMap: Record<string, number[]>,
): AiInvestigationParagraphWithSource[] => {
  // Getting the start and end indices of each sentence with source in the paragraph
  const sourcesStartEnd = Object.keys(lineToSourceNumberMap).reduce(
    (acc, line) => {
      const regex = new RegExp(line, 'g');
      const indices = paragraph.matchAll(regex);

      return [
        ...acc,
        ...[...indices].map((match) => ({
          start: match.index,
          end: match.index + line.length,
          sourceNumber: lineToSourceNumberMap[line],
        })),
      ];
    },
    [],
  );

  // If there are no sources in the paragraph, return the paragraph as is
  if (sourcesStartEnd.length === 0) {
    return [{ sentence: paragraph, sources: [], key: paragraph }];
  }

  // Sorting the sources start and end indices by start index
  // such that the sentences will appear in the correct order
  const sortedSourcesStartEnd = sourcesStartEnd.toSorted(
    (a, b) => a.start - b.start,
  );

  // Fill in the rest of the paragraph with null source number
  let currIdx = 0;
  const sentencesStartEnd = sortedSourcesStartEnd.reduce<
    { start: number; end: number; sourceNumber: number[] }[]
  >((acc, source) => {
    const { start, end, sourceNumber } = source;

    if (start > currIdx) {
      acc.push({
        start: currIdx,
        end: start,
        sourceNumber: [],
      });
    }

    acc.push({
      start,
      end,
      sourceNumber: sourceNumber.toSorted((a, b) => a - b),
    });
    currIdx = end;

    return acc;
  }, []);

  // To account for the case where last chunk does not have a source
  if (paragraph.length > currIdx) {
    sentencesStartEnd.push({
      start: currIdx,
      end: paragraph.length,
      sourceNumber: [],
    });
  }

  // Constructing the paragraph with source number
  return sentencesStartEnd.map((sentence) => {
    const { start, end, sourceNumber } = sentence;
    return {
      sentence: paragraph.slice(start, end),
      sources: sourceNumber,
      key: `${start}-${end}`,
    };
  });
};

export const computeAiInvestigationSources = (
  result: AiInvestigationResult,
) => {
  const { overall, references } = result;

  const {
    lineToSourceNumberMap,
    sourceNumberToExternalSourceMap,
    sourceNumberToInternalSourceMap,
  } = getSentenceSourceNumber(references ?? []);

  const paragraphWithSource = getParagraphWithSource(
    overall,
    lineToSourceNumberMap,
  );

  return {
    sourceNumberToExternalSourceMap,
    sourceNumberToInternalSourceMap,
    paragraphWithSource,
  };
};

import { ConfiguredFlow } from 'app/modules/dataSettings/types';
import { EntitySummary } from 'app/modules/entities/models';
import { TRANSACTION_SENDING_RECEIVING_OPTIONS } from 'app/modules/networkAnalysisRefresh/constants';
import { InstrumentSummary } from 'app/modules/txnInstruments/models';

export enum BaseObjectType {
  ENTITY = 'entity',
  INSTRUMENT = 'instrument',
}

export interface FilteredNetworkAnalysisData {
  objects: Set<EntityNodeData['id']>;
  links: Set<NetworkAnalysisLinkNodeData['id']>;
  transactions: Set<TransactionEdgeData['id']>;
  entityRelationships: Set<EntityToEntityRelationshipEdgeData['id']>;
  instrumentRelationships: Set<BaseEdgeData<NodeType.INSTRUMENT>['id']>;
}

export enum NodeType {
  ENTITY = 'entity',
  ADDRESS = 'address',
  CLIENT_FINGERPRINT = 'client_fingerprint',
  CUSTOM_FIELD = 'custom_field',
  EMAIL_ADDRESS = 'email_address',
  INSTRUMENT = 'instrument',
  IP_ADDRESS = 'ip_address',
  PHYSICAL_ID = 'physical_id',
  PHONE_NUMBER = 'phone_number',
  SSN = 'ssn',
}

export enum ConnectionType {
  ACCOUNT_NUMBER = 'account_number',
  ENTITY_RELATIONSHIP = 'entity_relationship',
  INSTRUMENT_RELATIONSHIP = 'instrument_relationship',
  TRANSACTION = 'transaction',
}

export interface BaseNodeData<T extends NodeType> {
  id: string;
  node_type: T;
  label: string;
}

export interface EntityNodeData extends BaseNodeData<NodeType.ENTITY> {
  node_type: NodeType.ENTITY;
  external_id: string;
  hashed_id: string;
  is_base_node?: boolean | null;
  display_name: string;
  entity_type: string | null;
  internal_entity_type: string | null;
  status: string | null;
}

export interface InstrumentNodeData extends BaseNodeData<NodeType.INSTRUMENT> {
  hashed_id: string;
  external_id: string;
  is_base_node?: boolean | null;
  instrument_type?: string;
  instrument_subtype?: string;
  source?: string;
  status?: string;
  p_account_number?: string; // Optional property
}

interface AddressNodeData extends BaseNodeData<NodeType.ADDRESS> {
  scourgify_hash: string;
  address_hash: string;
  address_string: string;
}

interface ClientFingerprintNodeData
  extends BaseNodeData<NodeType.CLIENT_FINGERPRINT> {
  client_fingerprint: string;
}

interface CustomFieldNodeData extends BaseNodeData<NodeType.CUSTOM_FIELD> {
  value: string | number;
  data_setting_id: number;
}

interface IpAddressNodeData extends BaseNodeData<NodeType.IP_ADDRESS> {
  ip_address: string;
}

interface ParsedEmailAddressNodeData
  extends BaseNodeData<NodeType.EMAIL_ADDRESS> {
  parsed_email_hash: string;
  email_address: string;
}

interface ParsedPhoneNumberNodeData
  extends BaseNodeData<NodeType.PHONE_NUMBER> {
  parsed_phone_number: string;
}

interface PhysicalIdNodeData extends BaseNodeData<NodeType.PHYSICAL_ID> {
  country?: string;
  equality_key: string;
  expires_at?: string;
  id_number: string;
  registered_on?: string;
  state?: string;
  type: string;
}

interface SSNNodeData extends BaseNodeData<NodeType.SSN> {
  SSN: string;
}

export type NetworkAnalysisLinkNodeData =
  | AddressNodeData
  | ClientFingerprintNodeData
  | CustomFieldNodeData
  | InstrumentNodeData
  | IpAddressNodeData
  | ParsedEmailAddressNodeData
  | ParsedPhoneNumberNodeData
  | PhysicalIdNodeData
  | SSNNodeData;

export type NetworkAnalysisNodeData =
  | EntityNodeData
  | NetworkAnalysisLinkNodeData;

export interface TransactionData {
  id: string;
  external_id: string;
  flow: ConfiguredFlow;
  amount: number;
  currency?: string | null;
  event_time: string;
}

export interface BaseEdgeData<T extends NodeType | ConnectionType> {
  id: string;
  source: string;
  target: string;
  edge_type: T;
}

export interface LinkEdgeData
  extends BaseEdgeData<
    Exclude<NodeType, NodeType.INSTRUMENT | NodeType.ENTITY>
  > {}

export interface TransactionEdgeData
  extends BaseEdgeData<ConnectionType.TRANSACTION> {
  transaction_data: TransactionData;
  label: string;
}

export interface EntityToEntityRelationshipEdgeData
  extends BaseEdgeData<ConnectionType.ENTITY_RELATIONSHIP> {
  label: string;
}

export interface EntityToInstrumentRelationshipEdgeData
  extends BaseEdgeData<NodeType.INSTRUMENT> {
  label: string;
}

export interface InstrumentToInstrumentRelationshipEdgeData
  extends BaseEdgeData<ConnectionType.INSTRUMENT_RELATIONSHIP> {
  label: string;
}

export interface InstrumentToEntityRelationshipEdgeData
  extends BaseEdgeData<NodeType.ENTITY> {
  label: string;
}

export interface AccountNumberEdgeData
  extends BaseEdgeData<ConnectionType.ACCOUNT_NUMBER> {
  label: string;
  is_soft_match: boolean;
}

export type NetworkAnalysisEdgeData =
  | LinkEdgeData
  | TransactionEdgeData
  | EntityToEntityRelationshipEdgeData
  | AccountNumberEdgeData
  | EntityToInstrumentRelationshipEdgeData
  | InstrumentToInstrumentRelationshipEdgeData
  | InstrumentToEntityRelationshipEdgeData;

export interface GraphResult {
  nodes: Record<string, NetworkAnalysisNodeData>;
  edges: Record<string, NetworkAnalysisEdgeData>;
}

export interface NetworkAnalysisRefreshResponse {
  graph_result: GraphResult;
  link_counts: Record<NodeType | ConnectionType, number>;
}

export type LinkGroupItem = NetworkAnalysisLinkNodeData & {
  objects: string[];
};

export type EntityGroupItem = EntityNodeData & {
  links: string[];
};

export type InstrumentGroupItem = InstrumentNodeData & {
  links: string[];
};

export interface NetworkAnalysisGroups {
  links: Record<string, LinkGroupItem>;
  objects:
    | Record<string, EntityGroupItem>
    | Record<string, InstrumentGroupItem>;
  transactions: Record<string, TransactionEdgeData>;
  entityRelationships: Record<
    string,
    EntityToEntityRelationshipEdgeData | InstrumentToEntityRelationshipEdgeData
  >;
  instrumentRelationships: Record<
    string,
    | EntityToInstrumentRelationshipEdgeData
    | InstrumentToInstrumentRelationshipEdgeData
  >;
}

export type LinkTypeFilters =
  | NodeType
  | ConnectionType.TRANSACTION
  | ConnectionType.ENTITY_RELATIONSHIP
  | ConnectionType.INSTRUMENT_RELATIONSHIP;

export interface TransactionFilters {
  min: number;
  direction: (typeof TRANSACTION_SENDING_RECEIVING_OPTIONS)[number]['value'];
}

export interface InstrumentNetworkAnalysisFilters {
  linkType: LinkTypeFilters[];
  transactionFilters: TransactionFilters;
}

export interface EntityNetworkAnalysisFilters
  extends InstrumentNetworkAnalysisFilters {
  entityType: string[];
  entitySubtype: string[];
  entityStatus: string[];
}

export type NetworkAnalysisFilters =
  | EntityNetworkAnalysisFilters
  | InstrumentNetworkAnalysisFilters;

export interface ObjectCounts {
  totalObjects: number;
  totalLinks: number;
  totalTransactions: number;
  totalEntityRelationships: number;
  totalInstrumentRelationships: number;
}

export interface MergedEntity extends EntitySummary {
  node_id: EntityGroupItem['id'];
  hashed_id: EntityGroupItem['hashed_id'];
  links: EntityGroupItem['links'];
}

export interface MergedInstrument extends InstrumentSummary {
  node_id: InstrumentGroupItem['id'];
  hashed_id: InstrumentGroupItem['hashed_id'];
  links: InstrumentGroupItem['links'];
}

import { IconProps } from 'app/shared/components/Icons/models';

export const IconFlagSparkle = ({
  size = 20,
  color = 'currentColor',
  stroke = 2,
  ...props
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    {...props}
  >
    <path
      d="M4.58331 7.01074V18.7917"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8917 7.01074V13.6375"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.58331 7.0104C5.2715 6.33585 6.19674 5.95801 7.1604 5.95801C8.12405 5.95801 9.04929 6.33585 9.73748 7.0104C10.4257 7.68496 11.3509 8.0628 12.3146 8.0628C13.2782 8.0628 14.2035 7.68496 14.8916 7.0104"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.69665 8.08083C7.7268 7.98916 7.85649 7.98916 7.88664 8.08083L8.08759 8.69185C8.18681 8.99354 8.42343 9.23016 8.72512 9.32938L9.33614 9.53033C9.42782 9.56048 9.42782 9.69017 9.33614 9.72032L8.72512 9.92127C8.42343 10.0205 8.18681 10.2571 8.08759 10.5588L7.88664 11.1698C7.85649 11.2615 7.7268 11.2615 7.69665 11.1698L7.4957 10.5588C7.39648 10.2571 7.15986 10.0205 6.85817 9.92127L6.24715 9.72032C6.15548 9.69017 6.15548 9.56048 6.24715 9.53033L6.85817 9.32938C7.15986 9.23016 7.39648 8.99354 7.4957 8.69185L7.69665 8.08083Z"
      fill={color}
    />
    <path
      d="M12.28 13.1218C12.3101 13.0302 12.4398 13.0302 12.47 13.1218L13.1247 15.1125C13.2239 15.4142 13.4605 15.6508 13.7622 15.75L15.7528 16.4047C15.8445 16.4348 15.8445 16.5645 15.7528 16.5947L13.7622 17.2493C13.4605 17.3486 13.2239 17.5852 13.1247 17.8869L12.47 19.8775C12.4398 19.9692 12.3101 19.9692 12.28 19.8775L11.6253 17.8869C11.5261 17.5852 11.2895 17.3486 10.9878 17.2493L8.99715 16.5947C8.90548 16.5645 8.90548 16.4348 8.99715 16.4047L10.9878 15.75C11.2895 15.6508 11.5261 15.4142 11.6253 15.1125L12.28 13.1218Z"
      fill={color}
    />
    <path
      d="M4.58331 13.7497C5.25648 13.1621 6.16152 12.833 7.10415 12.833C8.04677 12.833 8.95181 13.1621 9.62498 13.7497"
      stroke={color}
      strokeWidth={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8633 2.12185C16.8935 2.03017 17.0232 2.03017 17.0533 2.12185L17.4811 3.42267C17.5803 3.72436 17.817 3.96098 18.1186 4.0602L19.4195 4.48801C19.5111 4.51816 19.5111 4.64785 19.4195 4.678L18.1186 5.10582C17.817 5.20504 17.5803 5.44166 17.4811 5.74334L17.0533 7.04417C17.0232 7.13584 16.8935 7.13584 16.8633 7.04417L16.4355 5.74334C16.3363 5.44166 16.0997 5.20504 15.798 5.10582L14.4972 4.678C14.4055 4.64785 14.4055 4.51816 14.4972 4.48801L15.798 4.0602C16.0997 3.96098 16.3363 3.72436 16.4355 3.42267L16.8633 2.12185Z"
      fill={color}
    />
  </svg>
);

import { ReactNode } from 'react';

import styled from 'styled-components';

import {
  U21Form,
  U21FormField,
  U21FormLabel,
  U21Modal,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { Filters } from 'app/modules/filters/components/Filters';
import { Filter, FilterOption } from 'app/modules/filters/models';

export interface ExportModalOption {
  label: string;
  value: any;
}

interface Props {
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onExport: (values: {
    receive_email: boolean;
    use_csv: boolean;
  }) => void | Promise<void>;
  title: Exclude<ReactNode, null | undefined | boolean>;
  options?: ExportModalOption[];
  filters?: {
    label: string;
    filterOptions: FilterOption[];
    filterValues: Filter[];
    onFilterChange: (filters: Filter[]) => void;
  };
}

const EXPORT_FORM_ID = 'EXPORT_FORM_ID';

export const ExportModal = ({
  onClose,
  onExport,
  open,
  loading,
  title,
  options = [
    { label: 'Excel (xlsx)', value: false },
    { label: 'CSV', value: true },
  ],
  filters,
}: Props) => {
  return (
    <U21Modal
      actionButtonProps={{
        children: 'Export',
        form: EXPORT_FORM_ID,
        loading,
        type: 'submit',
      }}
      onClose={onClose}
      open={open}
      title={title}
    >
      <U21Form
        id={EXPORT_FORM_ID}
        initialValues={{
          use_csv: options[0].value ?? false,
          receive_email: false,
        }}
        onSubmit={onExport}
      >
        <U21Spacer spacing={3}>
          <Section>
            <StyledU21FormLabel>Export file type</StyledU21FormLabel>
            <StyledU21FormField
              type="RADIO_GROUP"
              name="use_csv"
              fieldProps={{
                columns: 2,
                horizontal: true,
                options,
              }}
            />
          </Section>
          <U21Typography variant="body2">
            Once confirmed, you can view your export request in the{' '}
            <strong>Reporting page &gt; File Exports</strong> tab. To be
            notified when the export is ready, you can opt in below to receive
            an email notification.
          </U21Typography>

          {filters && (
            <Section>
              <StyledU21FormLabel>{filters.label}</StyledU21FormLabel>
              <Filters
                filters={filters.filterValues}
                onChange={filters.onFilterChange}
                options={filters.filterOptions}
              />
            </Section>
          )}
          <Section>
            <StyledU21FormLabel>Receive email notification</StyledU21FormLabel>
            <U21FormField
              type="CHECKBOX"
              name="receive_email"
              fieldProps={{ label: 'Yes' }}
            />
          </Section>
        </U21Spacer>
      </U21Form>
    </U21Modal>
  );
};

const Section = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledU21FormLabel = styled(U21FormLabel)`
  width: 25%;
`;

const StyledU21FormField = styled(U21FormField)`
  flex: 1;
`;

import { get, post } from 'app/shared/utils/fetchr';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import {
  InvestigationsCaseResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadCasesPermissions } from 'app/modules/session/selectors';
import { selectDLEnabledForCaseDetail } from 'app/shared/featureFlags/selectors';

export const useGetCase = (
  id: number,
  options: Pick<
    UseQueryOptions<InvestigationsCaseResponse<false>>,
    'enabled'
  > = {},
) => {
  const useNewEndpoint = useSelector(selectDLEnabledForCaseDetail);

  const endpoint = useNewEndpoint
    ? `/cases/cached/${id}`
    : '/investigations/retrieve/case';

  const { enabled = true } = options;
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const query = useQuery({
    enabled: enabled && hasReadCasesPermission,
    // The query key is handled by the useNewEndpoint variable
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: CASE_QUERY_KEYS.getCase(id, useNewEndpoint),
    queryFn: () => {
      if (useNewEndpoint) {
        return get<InvestigationsCaseResponse<false>>(endpoint);
      }
      return post<InvestigationsCaseResponse<false>>(endpoint, {
        short: false,
        object_type: 'CASE',
        object_ids: [id],
      } satisfies InvestigationsPayload);
    },
  });
  return {
    ...query,
    case: query.data?.cases[0],
  };
};

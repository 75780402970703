import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';

const NETWORK_ANALYSIS_BASE_QUERY_KEY = ['networkAnalysis'];
export const GET_NETWORK_ANALYSIS_OBJECTS_BASE_QUERY_KEY = [
  ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
  'getNetworkAnalysisObjects',
];

export const NETWORK_ANALYSIS_QUERY_KEYS = {
  getUseNetworkAnalysisKey: (
    entityId: string | undefined,
    entityExternalId: string,
    deltaLakeEnabled: boolean,
  ) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'networkAnalysis',
      entityId,
      entityExternalId,
      deltaLakeEnabled,
    ] as const,
  getNetworkAnalysisKey: (externalId: string, baseObjectType: BaseObjectType) =>
    [...NETWORK_ANALYSIS_BASE_QUERY_KEY, baseObjectType, externalId] as const,
  getNetworkAnalysisStatusKey: (
    externalId: string,
    baseObjectType: BaseObjectType,
  ) =>
    [
      ...NETWORK_ANALYSIS_BASE_QUERY_KEY,
      'status',
      externalId,
      baseObjectType,
    ] as const,
  getEntitiesForNetworkAnalysis: (externalIds: string[]) =>
    [
      ...GET_NETWORK_ANALYSIS_OBJECTS_BASE_QUERY_KEY,
      'entities',
      externalIds,
    ] as const,
  getInstrumentsForNetworkAnalysis: (externalIds: string[]) =>
    [
      ...GET_NETWORK_ANALYSIS_OBJECTS_BASE_QUERY_KEY,
      'instruments',
      externalIds,
    ] as const,
};

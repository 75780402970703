import {
  ParameterUnits,
  ParameterValues,
} from 'app/modules/alertScore/types/models';
import { formatDuration } from 'date-fns';

export const getParameterDisplay = (
  value: ParameterValues,
  unit?: ParameterUnits,
) => {
  switch (unit) {
    case 'usd':
      if (typeof value === 'number') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          notation: 'compact',
        }).format(value);
      }
      return value;

    case 'day':
      if (typeof value === 'number') {
        return formatDuration({
          months: Math.floor(value / 30),
          days: value % 30,
        });
      }
      return value;

    case 'hour':
      if (typeof value === 'number') {
        return formatDuration({
          days: Math.floor(value / 24),
          hours: value % 24,
        });
      }
      return value;

    case 'second':
      if (typeof value === 'number') {
        return formatDuration({
          hours: Math.floor(value / 3600),
          minutes: Math.floor((value % 3600) / 60),
          seconds: (value % 3600) % 60,
        });
      }
      return value;

    case 'float': {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      if (Array.isArray(value)) {
        const narrowedValue = value as number[];
        if (narrowedValue.length === 0) {
          return 'none';
        }
        return new Intl.ListFormat('en-US').format(
          narrowedValue.map((i) => formatter.format(i)),
        );
      }
      if (typeof value === 'number') {
        return formatter.format(value);
      }
      return value;
    }

    case 'int':
      if (Array.isArray(value)) {
        const narrowedValue = value as number[];
        if (narrowedValue.length === 0) {
          return 'none';
        }
        return new Intl.ListFormat('en-US').format(
          narrowedValue.map((i) => `${i}`),
        );
      }
      return value;

    case 'str':
      if (Array.isArray(value)) {
        const narrowedValue = value as string[];
        if (narrowedValue.length === 0) {
          return 'none';
        }
        const distinctCounts = narrowedValue.reduce<Record<string, number>>(
          (acc, cur) => {
            if (acc[cur] === undefined) {
              acc[cur] = 1;
            } else {
              acc[cur] += 1;
            }
            return acc;
          },
          {},
        );
        const displayArray = Object.entries(distinctCounts).map(
          ([key, val]) => {
            if (val > 1) {
              return `${key} (${val}x)`;
            }
            return key;
          },
        );

        return new Intl.ListFormat('en-US').format(displayArray);
      }
      return value;

    default:
      return value;
  }
};

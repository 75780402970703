import { IconWorld } from '@u21/tabler-icons';
import { CoreSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/CoreSourceChip';
import { AiInvestigationExternalSource } from 'app/modules/investigations/types/responses';
import { U21Chip, U21ExternalLinkModal } from 'app/shared/u21-ui/components';
import { useState } from 'react';
import styled from 'styled-components';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationExternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const ExternalSourceChip = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  const { url, header } = sourceData;

  const [externalLinkModalOpen, setExternalLinkModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <CoreSourceChip
        sourceNumber={sourceNumber}
        isHovering={isHovering}
        setIsHovering={setIsHovering}
        chip={
          <StyledContentChip
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            icon={<IconWorld />}
            $isCurrHover={isHovering}
            variant="ghost"
            onClick={() => setExternalLinkModalOpen(true)}
          >
            {header ? `${header} (${url})` : `Web Search (${url})`}
          </StyledContentChip>
        }
      />

      <U21ExternalLinkModal
        onClose={() => setExternalLinkModalOpen(false)}
        open={externalLinkModalOpen}
        url={url}
      />
    </>
  );
};

const StyledContentChip = styled(U21Chip)<{ $isCurrHover: boolean }>`
  background-color: ${(props) =>
    props.$isCurrHover ? props.theme.palette.action.hover : 'inherit'};
`;

import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import {
  U21Section,
  U21Chart,
  U21Spacer,
  U21Typography,
  U21Button,
  U21Loading,
  U21Alert,
  U21LoadError,
  U21Subsection,
} from 'app/shared/u21-ui/components';
import { IconCircle, IconInfoCircle } from '@u21/tabler-icons';

// TS - interfaces
import { ApexOptions } from 'apexcharts';

// Query
import { useShapValues } from 'app/modules/alertScore/useShapValues';

// Constants
import {
  FEATURES_TO_ROLL,
  FROM_AI_COPILOT,
} from 'app/modules/alertScore/constants';
import { ALERT_SCORE_DOCS } from 'app/shared/constants/docs';

// Utils
import {
  getChartHeight,
  getFeatureName,
  getFeatureDescription,
  getFillColor,
} from 'app/modules/alertScore/utils';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { useLocalStorageState } from 'app/shared/hooks/useLocalStorage';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';
import { AlertScoreAiCopilotBanner } from 'app/modules/alertScore/components/AlertScoreAiCopilotBanner';
import {
  selectAlertScore,
  selectAlertScoreAiCopilotEnabled,
} from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';

export const AlertScore = () => {
  // Local state
  const [showFeatures, setShowFeatures] = useState(FEATURES_TO_ROLL - 1);
  const [hideInfo, setHideInfo] = useLocalStorageState<boolean>(
    LocalStorageKeys.ALERT_SCORE_HIDE_INFO,
    false,
  );

  const alertScoreEnabled = useSelector(selectAlertScore);
  const alertScoreAiCopilotEnabled = useSelector(
    selectAlertScoreAiCopilotEnabled,
  );

  // Aesthetics
  const { palette } = useTheme();
  const redColor = palette.error.main;
  const blueColor = palette.primary.main;
  const blackColor = palette.text.primary;
  const grayColor = palette.text.secondary;

  // Data fetching
  const { pathname, state: routerState } = useLocation();
  const alertId = useMemo(() => {
    return pathname.split('/')[2];
  }, [pathname]);
  const {
    data: shapValuesData,
    isLoading: loadingRetrieveShapValues,
    refetch,
  } = useShapValues(alertId);

  // Data formatting
  const [series, options] = useMemo(() => {
    if (shapValuesData) {
      // Try to use the new collapsed values if they exist, else fallback to legacy values
      const shapValues =
        shapValuesData.collapsed_values || shapValuesData.values;

      let currentTailValue = shapValuesData.base_value;
      let runningMax = Math.max(
        shapValuesData.max_response_score,
        shapValuesData.base_value,
      );
      let runningMin = Math.min(
        shapValuesData.max_response_score,
        shapValuesData.base_value,
      );

      const data: {
        x: string;
        y: [number, number];
        fillColor?: string;
      }[] = Object.keys(shapValues)
        .filter((featureVariable) => {
          return shapValues[featureVariable] !== 0;
        })
        .sort((a, b) => {
          return Math.abs(shapValues[b]) - Math.abs(shapValues[a]);
        })
        .slice(0, showFeatures)
        .map((featureVariable) => {
          const tailValue = currentTailValue;
          const headValue = shapValues[featureVariable] + currentTailValue;
          currentTailValue = headValue;
          if (headValue > runningMax) {
            runningMax = headValue;
          }
          if (headValue < runningMin) {
            runningMin = headValue;
          }
          let y: [number, number];

          if (shapValues[featureVariable] > 0) {
            y = [tailValue * 100, headValue * 100];
          } else {
            y = [headValue * 100, tailValue * 100];
          }
          return {
            x: featureVariable,
            y,
            fillColor: shapValues[featureVariable] > 0 ? redColor : blueColor,
          };
        });

      const remainingScore =
        currentTailValue - shapValuesData.max_response_score;
      if (Math.abs(remainingScore) > 0.00001) {
        if (remainingScore > 0) {
          data.push({
            x: 'OTHER_FEATURES',
            y: [
              shapValuesData.max_response_score * 100,
              currentTailValue * 100,
            ],
            fillColor: blueColor,
          });
        } else {
          data.push({
            x: 'OTHER_FEATURES',
            y: [
              currentTailValue * 100,
              shapValuesData.max_response_score * 100,
            ],
            fillColor: redColor,
          });
        }
      }

      const points = data.map((barData) => {
        if (barData.fillColor === blueColor) {
          return {
            y: barData.x,
            x: barData.y[0],
            label: {
              style: {
                color: blackColor,
              },
              text: (barData.y[0] - barData.y[1]).toFixed(2),
              textAnchor: 'end',
              offsetX: -10,
              offsetY: 9,
            },
            marker: { size: 0 },
          };
        }
        return {
          y: barData.x,
          x: barData.y[1],
          label: {
            style: {
              color: blackColor,
            },
            text: (barData.y[1] - barData.y[0]).toFixed(2),
            textAnchor: 'start',
            offsetX: 10,
            offsetY: 9,
          },
          marker: { size: 0 },
        };
      });

      const chartOptions: ApexOptions = {
        chart: {
          id: 'range-bar',
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        // this is used to set the tooltip title color
        colors: [blackColor],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        grid: {
          padding: {
            top: 0,
            right: 50,
            bottom: 10,
            left: 5,
          },
        },
        xaxis: {
          title: { text: 'Alert Score' },
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        yaxis: {
          title: { offsetX: 10, text: 'Variables' },
          labels: {
            minWidth: 150,
            align: 'left',
            formatter(val, opts?) {
              return getFeatureName(opts, val);
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: (opts) => {
            return `
          <div style="
                max-width: 250px;
                padding: 1rem;
                ">
            <div style="
                  color: ${getFillColor(opts)};
                  font-size: 1.125rem;
                  line-height: 1.75rem;
                  font-weight: 900;
                  margin-bottom: 5px;
                  white-space: normal;
                  ">${getFeatureName(opts, '')}
            </div>
            <div style="
                  color: ${grayColor};
                  margin-bottom: 5px;
                  white-space: normal;
                  ">${getFeatureDescription(opts)}
            </div>
          </div>`;
          },
        },
        annotations: {
          // @ts-ignore
          points,
          xaxis: [
            {
              x: shapValuesData.max_response_score * 100,
              strokeDashArray: 0,
              borderColor: shapValuesData.is_high_risk ? redColor : blueColor,

              // Background label colors: rgba(244,96,89,0.16) and rgba(24, 144, 255, 0.16) are used to mimic U21Label
              label: {
                borderColor: shapValuesData.is_high_risk
                  ? 'rgba(244, 96, 89, 0.16)'
                  : 'rgba(24, 144, 255, 0.16)',
                style: {
                  color: shapValuesData.is_high_risk ? redColor : blueColor,
                  background: shapValuesData.is_high_risk
                    ? 'rgba(244, 96, 89, 0.16)'
                    : 'rgba(24, 144, 255, 0.16)',
                },
                text: `Alert Score: ${(
                  shapValuesData.max_response_score * 100
                ).toFixed(2)}`,
                position: 'top',
                orientation: 'horizontal',
                offsetY: -15,
              },
            },
          ],
        },
      };

      return [
        [
          {
            name: 'SHAP values',
            data,
          },
        ],
        chartOptions,
      ];
    }
    return [undefined, undefined];
  }, [
    grayColor,
    blackColor,
    blueColor,
    redColor,
    shapValuesData,
    showFeatures,
  ]);

  const showRiskAssessment = useMemo(
    () => alertScoreAiCopilotEnabled && alertScoreEnabled,
    [alertScoreAiCopilotEnabled, alertScoreEnabled],
  );

  // Scrolling
  const alertScoreRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  useEffect(() => {
    if (routerState === FROM_AI_COPILOT && alertScoreRef.current) {
      alertScoreRef.current.scrollIntoView({ behavior: 'smooth' });
      history.replace({ pathname });
    }
  }, [history, pathname, routerState]);

  return (
    <StyledAlertScoreCard
      title={
        <U21Spacer horizontal>
          <U21Typography variant="h4">Alert Score</U21Typography>
          <U21Button
            color="info"
            icon={<IconInfoCircle />}
            onClick={() => setHideInfo((oldValue) => !oldValue)}
          />
        </U21Spacer>
      }
    >
      <U21Loading loading={loadingRetrieveShapValues} />
      {series && (
        <U21Spacer ref={alertScoreRef} spacing={!hideInfo ? 5 : 0}>
          <U21Alert
            severity="info"
            hidden={hideInfo}
            onClose={() => setHideInfo(true)}
            title="What is an Alert Score?"
          >
            <StyledAlertInfoContent spacing={2}>
              <U21Spacer>
                <U21Typography variant="body2">
                  Alert Scores represent the severity of an alert according to
                  Unit21&apos;s machine learning model. Higher scores indicate
                  that an alert is high severity. Chart below provides pictorial
                  representation of how the alert variables affect the final
                  score.
                </U21Typography>
                <StyledUl>
                  <li>
                    <U21Typography variant="body2">
                      Red variables: Number to the <strong>right</strong> is the{' '}
                      <strong>increase</strong> of the Alert Score by the
                      indicated variable.
                    </U21Typography>
                  </li>
                  <li>
                    <U21Typography variant="body2">
                      Blue variables: Number to the <strong>left</strong> is the{' '}
                      <strong>decrease</strong> of the Alert Score by the
                      indicated variable.
                    </U21Typography>
                  </li>
                </StyledUl>
              </U21Spacer>
              <U21Typography variant="body2">
                For more information on the alert score,{' '}
                <U21Button
                  color="primary"
                  href={ALERT_SCORE_DOCS}
                  tooltip={U21_DOCS_PASSWORD_TOOLTIP}
                  variant="text"
                >
                  please review the documentation.
                </U21Button>
              </U21Typography>
            </StyledAlertInfoContent>
          </U21Alert>

          {showRiskAssessment && (
            <U21Subsection title="Risk Assessment">
              <AlertScoreAiCopilotBanner />
            </U21Subsection>
          )}

          <U21Spacer>
            <U21Chart
              options={options}
              series={series}
              type="rangeBar"
              height={getChartHeight(showFeatures)}
            />
            <StyledU21Spacer horizontal>
              <U21Spacer>
                <U21Typography
                  variant="caption"
                  icon={<IconCircle fill={redColor} color={redColor} />}
                >
                  Red variables contribute to increase Alert Score
                </U21Typography>
                <U21Typography
                  variant="caption"
                  icon={<IconCircle fill={blueColor} color={blueColor} />}
                >
                  Blue variables contribute to decrease Alert Score
                </U21Typography>
              </U21Spacer>
              <U21Spacer horizontal>
                {showFeatures > FEATURES_TO_ROLL && (
                  <U21Button
                    color="primary"
                    onClick={() => {
                      setShowFeatures(
                        (oldValue) => oldValue - FEATURES_TO_ROLL,
                      );
                    }}
                  >
                    Show Less
                  </U21Button>
                )}
                <U21Button
                  color="primary"
                  onClick={() => {
                    setShowFeatures((oldValue) => oldValue + FEATURES_TO_ROLL);
                  }}
                  disabled={showFeatures >= series[0].data.length}
                  tooltip={
                    showFeatures >= series[0].data.length
                      ? 'All displayed!'
                      : undefined
                  }
                  tooltipProps={{ placement: 'top' }}
                >
                  Show More
                </U21Button>
              </U21Spacer>
            </StyledU21Spacer>
          </U21Spacer>
        </U21Spacer>
      )}
      {!series && !loadingRetrieveShapValues && (
        <U21LoadError label="alert score" onTryAgain={() => refetch()} />
      )}
    </StyledAlertScoreCard>
  );
};

const StyledAlertScoreCard = styled(U21Section)`
  min-height: 412px;
`;

const StyledU21Spacer = styled(U21Spacer)`
  justify-content: space-between;
`;

const StyledAlertInfoContent = styled(U21Spacer)`
  margin-top: 0.75rem;
`;

const StyledUl = styled.ul`
  &&& {
    margin-left: 1rem;
  }
`;

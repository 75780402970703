import {
  FullAlertResponse,
  ShortAlertResponse,
} from 'app/modules/alerts/types/responses';
import {
  FullCaseResponse,
  ShortCaseResponse,
} from 'app/modules/casesOld/types/responses';

export interface InvestigationsAlertResponse<Short extends boolean = true> {
  alerts: Short extends true ? ShortAlertResponse[] : FullAlertResponse[];
  count: number;
  data_loader?: DataLoaderState<'ALERT'>;
}

export enum DataLoaderStatus {
  NOT_STARTED = 'NOT_STARTED',
  TO_QUEUE = 'TO_QUEUE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export type DataLoaderJobType = 'txn_event' | 'instrument';

export interface DataLoaderJobState {
  status: DataLoaderStatus;
  seconds_since_execution: number | null;
}

// This allows us to determine which articles have outdated cache data and when was the last time that the cache job was executed so we can show the right loading state
export interface DataLoaderState<T extends 'ALERT' | 'CASE' | 'SAR_FILING'> {
  article_type: T;
  article_ids: {
    [id: number]: {
      [jobType in DataLoaderJobType]: DataLoaderJobState;
    };
  };
}

export interface InvestigationsCaseResponse<Short extends boolean = true> {
  cases: Short extends true ? ShortCaseResponse[] : FullCaseResponse[];
  count: number;
}
export interface InvestigationsAgentStatsResponse {
  open_count: number;
  completed_last_24_hrs_count: number;
  fetched_at: string;
}

export interface AiInvestigationResultItem {
  section: string;
  item_key: string;
  task: string;
  result: string; // NOTE: might be a stringified object...
  system_output: string; // NOTE: will be used as checklist input
  actuator_name?: string;
  sources?: AiInvestigationResultSource;
  method_calls?: Record<string, unknown>;
}

export interface AiInvestigationResultSectionItem {
  [itemKey: string]: AiInvestigationResultItem;
}

export interface AiInvestigationResultSection {
  [sectionkey: string]: AiInvestigationResultSectionItem;
}

export interface AiInvestigationExternalSource {
  url: string;
  header?: string;
}

export interface AiInvestigationInternalSource {
  data_type: 'ACTION_EVENT' | 'ALERT' | 'ENTITY' | 'INSTRUMENT' | 'TRANSACTION';
  external_id?: string | null;
  id: number;
}

export type AiInvestigationResultSource =
  | {
      source_type: 'Internet';
      links: AiInvestigationExternalSource[];
    }
  | {
      source_type: 'Internal';
      data_ref: AiInvestigationInternalSource[];
    }
  | {
      source_type: 'Internal' | 'Internet';
      data_ref: null;
      links: null;
    };

export interface AiInvestigationResultReference {
  content_line: string;
  sources: AiInvestigationResultSource[] | null;
}

export interface AiInvestigationResult {
  overall: string;
  sections: AiInvestigationResultSection;
  references?: AiInvestigationResultReference[];
}

export interface AiInvestigationResultsResponsePopulated {
  id: number;
  article_id: number;
  custom_checklist_submission_id: number;
  updated_at: string;
  status: 'PENDING' | 'COMPLETED' | 'ERROR';
  status_message: string;
  results: AiInvestigationResult | null;
}

export type AiInvestigationResultsResponse =
  | {
      current_ai_investigations_results: AiInvestigationResultsResponsePopulated;
      historical_ai_investigations_results: AiInvestigationResultsResponsePopulated[];
      narrative?: string;
    }
  | Record<any, never>;

import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { GetTransactionsResponse } from 'app/modules/transactions/responses';

import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';

import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { selectDLEnabledForTxnEventList } from 'app/shared/featureFlags/selectors';

export const useGetTransactions = (payload: GetTransactionsPayload) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const useNewEndpoint = useSelector(selectDLEnabledForTxnEventList);

  const endpoint = useNewEndpoint
    ? '/txn-events/cached/list'
    : '/txn-events/list';

  return useQuery({
    queryFn: () => post<GetTransactionsResponse>(endpoint, payload),
    queryKey: [...TRANSACTION_QUERY_KEYS.getTransactions(payload), endpoint],
    enabled: hasReadEventsPermission,
  });
};

import { useQuery } from '@tanstack/react-query';
import { getEntityByExternalId } from 'app/shared/api/entities';
import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';

export const useGetEntityByExternalId = (externalId: string) => {
  // DEPRECATED: This query is deprecated and should be removed once the cache entity details is working as expected
  return useQuery({
    queryKey: ENTITY_QUERY_KEYS.getEntityDetails(externalId, false),
    queryFn: () => getEntityByExternalId(externalId),
    meta: { errorMessage: 'Failed to retrieve entity.' },
    // TODO SC-60344 Manually fetching it until we get rid of the get entity by unit21 id
    enabled: Boolean(externalId),
  });
};

import { ENTITY_RELATIONSHIPS_SECTION_HASH } from 'app/modules/networkAnalysis/constants';
import {
  U21_NO_VALUE,
  U21Badge,
  U21Chip,
  U21Section,
  U21ShowMoreList,
  U21Table,
  U21TableBodyCellProps,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  BaseObjectType,
  ConnectionType,
  GraphResult,
  InstrumentNodeData,
  MergedEntity,
  NetworkAnalysisGroups,
  NodeType,
} from 'app/modules/networkAnalysisRefresh/types';
import { NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME } from 'app/modules/networkAnalysisRefresh/constants';
import { RelatedEntityDirectedness } from 'app/modules/relationships/models';
import { DIRECTEDNESS_TO_LABEL_MAP } from 'app/modules/relationships/columns';
import { selectEntityToEntityRelationshipConfigsByExternalId } from 'app/modules/relationships/selectors';
import {
  NetworkAnalysisContextState,
  useNetworkAnalysisContext,
} from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import {
  isEntityNode,
  getLinksColumn,
} from 'app/modules/networkAnalysisRefresh/helpers';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { selectEntityTableConfig } from 'app/shared/CustomConfig/selectors';
import { LinkSectionActions } from 'app/modules/networkAnalysisRefresh/components/LinkSection';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { selectSidebarEntityID } from 'app/modules/sidebar/selectors';

interface EntityRelationshipLinkTableRow extends MergedEntity {
  rowId: string;
  relationship_config_external_id: string;
  directedness?: RelatedEntityDirectedness;
}

export const EntityRelationshipsSection = () => {
  const {
    data,
    networkGroups,
    expandedSections,
    getToggleExpandedSection,
    handleLinkSectionMounted,
    baseObjectType,
    fetchedEntities,
  } = useNetworkAnalysisContext();

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return handleLinkSectionMounted(
      ENTITY_RELATIONSHIPS_SECTION_HASH,
      sectionRef,
    );
  }, [handleLinkSectionMounted]);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const linkedRelationshipsLength = useMemo(
    () => Object.keys(networkGroups.entityRelationships).length,
    [networkGroups.entityRelationships],
  );

  const tableData = useMemo(
    () =>
      data
        ? makeEntityRelationshipTableData(
            fetchedEntities,
            networkGroups,
            data.graph_result.nodes,
            baseObjectType,
          )
        : [],
    [fetchedEntities, networkGroups, data, baseObjectType],
  );

  const reduxDispatch = useDispatch();

  const relationshipConfigsByExternalId = useSelector(
    selectEntityToEntityRelationshipConfigsByExternalId,
  );

  const entityTableConfig = useSelector(selectEntityTableConfig);

  const columns: U21TableColumn<EntityRelationshipLinkTableRow, any>[] =
    useMemo(() => {
      let cols: U21TableColumn<EntityRelationshipLinkTableRow, any>[] = [
        {
          id: 'relationship_config',
          accessor: 'relationship_config_external_id',
          Header: 'Relationship',
          Cell: ({
            value,
          }: U21TableBodyCellProps<EntityRelationshipLinkTableRow, string>) => {
            return relationshipConfigsByExternalId[value]?.name ?? value;
          },
          disableSortBy: true,
        },
        {
          id: 'directedness',
          accessor: 'directedness',
          Header: 'Direction',
          Cell: ({
            value,
          }: U21TableBodyCellProps<
            EntityRelationshipLinkTableRow,
            RelatedEntityDirectedness
          >) => {
            return DIRECTEDNESS_TO_LABEL_MAP[value] ?? null;
          },
          disableSortBy: true,
        },
      ];
      if (data && baseObjectType === BaseObjectType.INSTRUMENT) {
        cols.push({
          id: 'instruments',
          Header: 'Related instruments',
          Cell: ({
            row,
          }: U21TableBodyCellProps<
            EntityRelationshipLinkTableRow,
            any,
            any
          >) => {
            const objects = networkGroups.links[row.id]?.objects.reduce<
              InstrumentNodeData[]
            >((acc, e) => {
              const instrument = data?.graph_result?.nodes[e];
              if (
                instrument &&
                instrument.node_type === NodeType.INSTRUMENT &&
                !instrument.is_base_node
              ) {
                acc.push(instrument);
              }
              return acc;
            }, []);
            if (!objects?.length) {
              return U21_NO_VALUE;
            }
            return (
              <U21ShowMoreList
                value={objects}
                displayFunc={(value) => {
                  return (
                    <U21Chip
                      onClick={(e) => {
                        e.stopPropagation();
                        reduxDispatch(
                          toggleSidebar({
                            type: SidebarComponentTypes.ENTITY,
                            data: {
                              id: 0,
                              externalId: value.external_id,
                            },
                          }),
                        );
                      }}
                    >
                      {value.external_id}
                    </U21Chip>
                  );
                }}
              />
            );
          },
        });
      } else if (data && baseObjectType === BaseObjectType.ENTITY) {
        cols.push(
          getLinksColumn<EntityRelationshipLinkTableRow>(
            data.graph_result.nodes,
            networkGroups,
            history,
            pathname,
            search,
          ),
        );
      }
      cols = [
        ...cols,
        ...createTableColumnConfig<EntityRelationshipLinkTableRow>(
          entityTableConfig,
          ENTITY_COLUMN_CONFIG as Record<
            string,
            Partial<U21TableColumn<EntityRelationshipLinkTableRow>>
          >,
        ),
      ];
      return cols;
    }, [
      data,
      baseObjectType,
      entityTableConfig,
      relationshipConfigsByExternalId,
      networkGroups,
      reduxDispatch,
      history,
      pathname,
      search,
    ]);

  const sidebarEntityID = useSelector(selectSidebarEntityID);
  const highlighted = useMemo(() => {
    if (!sidebarEntityID) {
      return undefined;
    }
    const row = tableData.find((i) => i.id === sidebarEntityID);
    if (!row) {
      return undefined;
    }
    return [row.rowId];
  }, [sidebarEntityID, tableData]);

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Badge content={linkedRelationshipsLength}>
            <span>
              {`${
                NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME[
                  ConnectionType.ENTITY_RELATIONSHIP
                ]
              }s`}
            </span>
          </U21Badge>
        }
        collapsible
        collapsed={!expandedSections.has(ENTITY_RELATIONSHIPS_SECTION_HASH)}
        onToggleCollapse={getToggleExpandedSection(
          ENTITY_RELATIONSHIPS_SECTION_HASH,
        )}
        action={
          <LinkSectionActions
            tableConfigType={TableConfigType.ENTITY_TABLE}
            hash={ENTITY_RELATIONSHIPS_SECTION_HASH}
          />
        }
      >
        <U21Table<EntityRelationshipLinkTableRow>
          data={tableData}
          columns={columns}
          highlighted={highlighted}
          onRowClick={(_, { id }) =>
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id,
                },
              }),
            )
          }
          getRowID={({ rowId }: EntityRelationshipLinkTableRow) => rowId}
        />
      </U21Section>
    </div>
  );
};

const makeEntityRelationshipTableData = (
  fetchedEntities: NetworkAnalysisContextState['fetchedEntities'],
  { entityRelationships, objects }: NetworkAnalysisGroups,
  nodes: GraphResult['nodes'],
  baseObjectType: BaseObjectType,
): EntityRelationshipLinkTableRow[] =>
  Object.values(entityRelationships).reduce<EntityRelationshipLinkTableRow[]>(
    (acc, entityRelationshipEdge) => {
      const { id, source, target, label } = entityRelationshipEdge;
      if (baseObjectType === BaseObjectType.INSTRUMENT) {
        // related entity is always the target for entity<>instrument relationships
        const targetEntity = nodes[target];
        if (isEntityNode(targetEntity)) {
          const fetchedEntity = fetchedEntities[targetEntity.external_id];
          if (!fetchedEntity) {
            return acc;
          }
          acc.push({
            ...fetchedEntity,
            node_id: targetEntity.id,
            hashed_id: targetEntity.hashed_id,
            links: [], // no links for a related entity on an instrument graph
            rowId: id,
            relationship_config_external_id: label,
          });
        }
      } else if (baseObjectType === BaseObjectType.ENTITY) {
        const sourceEntity = nodes[source];
        const targetEntity = nodes[target];
        if (!isEntityNode(sourceEntity) || !isEntityNode(targetEntity)) {
          return acc;
        }
        // take non-base node
        const entity = sourceEntity.is_base_node
          ? objects[target]
          : objects[source];
        const fetchedEntity = fetchedEntities[entity.external_id];
        if (!fetchedEntity) {
          return acc;
        }
        acc.push({
          ...fetchedEntity,
          node_id: entity.id,
          hashed_id: entity.hashed_id,
          links: entity.links,
          rowId: id,
          relationship_config_external_id: label,
          directedness: sourceEntity.is_base_node
            ? RelatedEntityDirectedness.TO
            : RelatedEntityDirectedness.FROM,
        });
      }
      return acc;
    },
    [],
  );

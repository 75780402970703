import { IconSparkles } from '@u21/tabler-icons';
import { AiAgentAdmin } from 'app/modules/aiAdmin/AiAgentAdmin';
import { ExperimentationAdmin } from 'app/modules/aiAdmin/ExperimentationAdmin';
import { PromptsAdmin } from 'app/modules/aiAdmin/PromptsAdmin';
import { U21Page } from 'app/shared/u21-ui/components';
import { U21PermissionedTabRouter } from 'app/shared/u21-ui/components/dashboard';
import { ROUTES_MAP } from 'app/shared/utils/routes';

export const AiAdminDashboard = () => {
  return (
    <U21Page title="AI Admin Dashboard" titleIcon={<IconSparkles />}>
      <U21PermissionedTabRouter
        tabs={[
          {
            component: <AiAgentAdmin />,
            label: 'AI Agents',
            path: ROUTES_MAP.aiAdminAgents.path,
            permissions: ROUTES_MAP.aiAdminAgents.permissions,
          },
          {
            component: <PromptsAdmin />,
            label: 'Prompts',
            path: ROUTES_MAP.aiAdminPrompts.path,
            permissions: ROUTES_MAP.aiAdminPrompts.permissions,
          },
          {
            component: <ExperimentationAdmin />,
            label: 'Experimentation',
            path: ROUTES_MAP.aiAdminExperimentation.path,
            permissions: ROUTES_MAP.aiAdminExperimentation.permissions,
          },
        ]}
      />
    </U21Page>
  );
};

import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import {
  U21TableColumn,
  U21TableBodyCellProps,
  U21NoValue,
} from 'app/shared/u21-ui/components';
import {
  U21EntityChip,
  U21InstrumentChip,
} from 'app/shared/u21-ui/components/dashboard';

type ActionEventTableColumn = U21TableColumn<ShortActionEventResponse, any>;

const ID_COLUMN: ActionEventTableColumn = {
  id: 'id',
  accessor: (row) => row.id,
};

const ENTITY_ID_COLUMN: ActionEventTableColumn = {
  id: 'entity_id',
  Cell: ({ row }: U21TableBodyCellProps<ShortActionEventResponse>) =>
    row.entity_id ? (
      <U21EntityChip
        id={row.entity_id}
        name={row.entity_external_id || `Entity #${row.entity_id}`}
      />
    ) : (
      <U21NoValue />
    ),
};

const INSTRUMENT_ID_COLUMN: ActionEventTableColumn = {
  id: 'instrument_id',
  Cell: ({ row }: U21TableBodyCellProps<ShortActionEventResponse>) =>
    row.instrument_id ? (
      <U21InstrumentChip
        id={row.instrument_id}
        label={row.instrument_external_id || `Instrument #${row.instrument_id}`}
      />
    ) : (
      <U21NoValue />
    ),
  disableSortBy: true, // API 500s if sorted by this column
};

export const ACTION_EVENTS_COLUMN_CONFIG = {
  [ID_COLUMN.id]: ID_COLUMN,
  [ENTITY_ID_COLUMN.id]: ENTITY_ID_COLUMN,
  [INSTRUMENT_ID_COLUMN.id]: INSTRUMENT_ID_COLUMN,
};

import PERMISSIONS, { Permission } from 'app/shared/utils/permissions';

export const PERMISSION_NAMES: Record<Permission, string> = {
  [PERMISSIONS.rootAll]: 'Unit21 admin',
  [PERMISSIONS.editAdminDashboard]: 'Manage admin dashboard',
  [PERMISSIONS.editRulesAdminDashboard]: 'Manage rule admin dashboard',
  [PERMISSIONS.editAiAdminDashboard]: 'Manage AI admin dashboard',
  [PERMISSIONS.readOrgPermissions]: 'Access other org agent permissions',
  [PERMISSIONS.editOrgPermissions]: 'Edit other org agent permissions',
  [PERMISSIONS.adminOrgSwitcherPermissions]: 'Manage admin org switcher',
  [PERMISSIONS.readPermissions]: 'Access permissions',
  [PERMISSIONS.editPermissions]: 'Edit permissions',
  [PERMISSIONS.readCases]: 'Access cases',
  [PERMISSIONS.createCases]: 'Create cases',
  [PERMISSIONS.editCases]: 'Edit cases',
  [PERMISSIONS.editStorage]: 'Manage Google Drive integration settings',
  [PERMISSIONS.editNotificationSettings]: 'Manage notification settings',
  [PERMISSIONS.editAccountSettings]: 'Manage user profile',
  [PERMISSIONS.readCustomDataSettings]: 'Access data display settings',
  [PERMISSIONS.editCustomDataSettings]: 'Manage data display settings',
  [PERMISSIONS.editDeadlinesConfig]: 'Manage global deadline settings',
  [PERMISSIONS.readDeadlinesConfig]: 'Access global deadline settings',
  [PERMISSIONS.createDeadlinesConfig]: 'Create global deadline settings',
  [PERMISSIONS.editDeadlines]: 'Edit deadlines',
  [PERMISSIONS.readDatafileUploads]: 'Access data file uploads',
  [PERMISSIONS.createDatafileUploads]: 'Upload data files',
  [PERMISSIONS.deleteDatafileUploads]: 'Delete data files',
  [PERMISSIONS.createAlerts]: 'Create manual alerts',
  [PERMISSIONS.readAlerts]: 'Access alerts',
  [PERMISSIONS.editAlerts]: 'Edit alerts',
  [PERMISSIONS.readAlertComponentButtons]: 'Access alert component buttons',
  [PERMISSIONS.editQueues]: 'Manage queues',
  [PERMISSIONS.readRules]: 'Access rules',
  [PERMISSIONS.createRules]: 'Create rules',
  [PERMISSIONS.editRules]: 'Edit rules',
  [PERMISSIONS.readAgents]: 'Access agent info',
  [PERMISSIONS.editAgents]: 'Manage agents',
  [PERMISSIONS.createTeams]: 'Create teams',
  [PERMISSIONS.readTeams]: 'Access teams',
  [PERMISSIONS.editTeams]: 'Edit teams',
  [PERMISSIONS.deleteTeams]: 'Delete teams',
  [PERMISSIONS.readAssignments]: 'Access alert, case, and filing admin actions',
  [PERMISSIONS.readEntities]: 'Access entities',
  [PERMISSIONS.editEntities]: 'Edit entities',
  [PERMISSIONS.readEntityMedia]: 'Access entity documents',
  [PERMISSIONS.readLinkAnalysis]: 'Access link analysis',
  [PERMISSIONS.readCensorshipConditions]: 'Access censorship settings',
  [PERMISSIONS.createCensorshipConditions]: 'Create censorship settings',
  [PERMISSIONS.editCensorshipConditions]: 'Edit censorship settings',
  [PERMISSIONS.deleteCensorshipConditions]: 'Delete censorship settings',
  [PERMISSIONS.readDevices]: 'Access devices',
  [PERMISSIONS.editDevices]: 'Edit devices',
  [PERMISSIONS.editEvents]: 'Edit actions and transactions',
  [PERMISSIONS.readEvents]: 'Access actions and transactions',
  [PERMISSIONS.readTxnInstruments]: 'Access instruments',
  [PERMISSIONS.editTxnInstruments]: 'Edit instruments',
  [PERMISSIONS.editTxnInstrumentActions]: 'Action instruments',
  [PERMISSIONS.editLockUnlockTxnInstruments]: 'Lock and unlock instruments',
  [PERMISSIONS.editDeactivateTxnInstruments]: 'Deactivate instruments',
  [PERMISSIONS.readSars]: 'Access filings',
  [PERMISSIONS.createSars]: 'Create filings',
  [PERMISSIONS.editSars]: 'Edit filings',
  [PERMISSIONS.editSarsConfig]: 'Manage filing settings',
  [PERMISSIONS.submitSars]: 'Submit filings',
  [PERMISSIONS.createRiskRatings]: 'Create risk ratings',
  [PERMISSIONS.readRiskRatings]: 'Access risk ratings',
  [PERMISSIONS.editRiskRatings]: 'Edit risk ratings',
  [PERMISSIONS.createApiKeys]: 'Create API keys',
  [PERMISSIONS.readApiKeys]: 'Access API keys',
  [PERMISSIONS.deleteApiKeys]: 'Delete API keys',
  [PERMISSIONS.createDashboards]: 'Create dashboards',
  [PERMISSIONS.editDashboards]: 'Edit dashboards',
  [PERMISSIONS.deleteDashboards]: 'Delete dashboards',
  [PERMISSIONS.readInsights]: 'Access dashboards',
  [PERMISSIONS.readWebhooks]: 'Access workflow management',
  [PERMISSIONS.editWebhooks]: 'Edit workflows',
  [PERMISSIONS.createWebhooks]: 'Create workflows',
  [PERMISSIONS.deleteWebhooks]: 'Delete workflows',
  [PERMISSIONS.readBlacklists]: 'Access matchlists',
  [PERMISSIONS.editBlacklists]: 'Edit matchlists',
  [PERMISSIONS.createBlacklists]: 'Create matchlists',
  [PERMISSIONS.deleteBlacklists]: 'Delete matchlists',
  [PERMISSIONS.readQuality]: 'Access quality cycles',
  [PERMISSIONS.editQuality]: 'Manage quality cycles',
  [PERMISSIONS.readDDBConnections]: 'Access FFIP streams and files',
  [PERMISSIONS.editDDBConnections]: 'Edit FFIP streams',
  [PERMISSIONS.createDDBConnections]: 'Create FFIP streams and files',
  [PERMISSIONS.deleteDDBConnections]: 'Delete FFIP streams and files',
  [PERMISSIONS.editTags]: 'Manage tags',
  [PERMISSIONS.adminAPI]: 'Manage admin settings',
  [PERMISSIONS.systemExports]: 'Access system file exports',
  [PERMISSIONS.readPans]: 'Decrypt encrypted data',
  [PERMISSIONS.create314aScans]: 'Manage 314(a) scans',
};

export const PERMISSION_DESCRIPTIONS: Partial<Record<Permission, string>> = {
  [PERMISSIONS.rootAll]: 'Permission that bypasses all permissions',
  [PERMISSIONS.readPermissions]: 'Access agent and team permissions',
  [PERMISSIONS.editPermissions]: 'Edit agent and team permissions',
  [PERMISSIONS.editCases]:
    'Edit content, change assignments, apply workflow buttons, edit checklist, manage documents, make notes, write narratives, add comments, and more',
  [PERMISSIONS.readCustomDataSettings]:
    'Access data settings, table settings, summary settings, watchlist fields, and more',
  [PERMISSIONS.editCustomDataSettings]:
    'Manage data settings, table settings, summary settings, watchlist fields and more',
  [PERMISSIONS.editDeadlines]: 'Edit alert, case, and filing deadlines',
  [PERMISSIONS.editDeadlinesConfig]: 'Edit and delete global deadline settings',
  [PERMISSIONS.readDatafileUploads]:
    'Access data file uploads and view prior data uploads',
  [PERMISSIONS.createDatafileUploads]: 'Upload new data into Unit21',
  [PERMISSIONS.editAlerts]:
    'Change assignments, apply workflow buttons, edit checklist, manage documents, make notes, write narratives, apply tags, add comments, and more',
  [PERMISSIONS.editQueues]: 'Access, create, edit and delete queue settings',
  [PERMISSIONS.editRules]:
    'Manage state, apply tags, set queue, add comments, manage allowlists, and more',
  [PERMISSIONS.editAgents]: 'Manage agent info, teams, status, and more',
  [PERMISSIONS.editTeams]: 'Edit team agents, settings, and more',
  [PERMISSIONS.readAssignments]:
    'Access alert, case, and filing admin tabs to change assignment, apply workflow buttons, export data, and more in bulk',
  [PERMISSIONS.editEntities]:
    'Manage documents, add comments, silence rules, apply tags, and more',
  [PERMISSIONS.readLinkAnalysis]: 'Access alert and entity link analysis tool',
  [PERMISSIONS.editDevices]: 'Add comments, apply tags, and more',
  [PERMISSIONS.editEvents]:
    'Add action and transaction comments, apply tags, and more',
  [PERMISSIONS.editTxnInstruments]:
    'Add comments, silence rules, apply tags, and more',
  [PERMISSIONS.editSars]: 'Edit content, manage assignment, and more',
  [PERMISSIONS.editSarsConfig]: 'Manage filing templates and settings',
  [PERMISSIONS.submitSars]: 'Submit filings to regulatory agencies',
  [PERMISSIONS.editDashboards]: 'Edit content, change layout, and more',
  [PERMISSIONS.readWebhooks]:
    'Access the configuration of workflow buttons, investigation checklists, narrative templates, webhooks, dispositions, and more',
  [PERMISSIONS.editWebhooks]:
    'Edit the configuration of workflow buttons, investigation checklists, narrative templates, webhooks, dispositions, and more',
  [PERMISSIONS.createWebhooks]:
    'Create workflow buttons, investigation checklists, narrative templates, webhooks, dispositions, and more',
  [PERMISSIONS.deleteWebhooks]:
    'Delete workflow buttons, investigation checklists, narrative templates, webhooks, dispositions, and more',
  [PERMISSIONS.editBlacklists]: 'Edit matchlist content',
  [PERMISSIONS.editQuality]: 'Create, edit, run, and delete quality cycles',
  [PERMISSIONS.editDDBConnections]:
    'Edit streams, map schemas, process files, and more',
  [PERMISSIONS.editTags]: 'Create and delete tags',
  [PERMISSIONS.editTxnInstrumentActions]:
    'Apply instrument actions such as deactivate, lock, and more',
  [PERMISSIONS.editLockUnlockTxnInstruments]:
    'Ability to lock and unlock instruments',
  [PERMISSIONS.editDeactivateTxnInstruments]:
    'Ability to deactivate instruments',
  [PERMISSIONS.create314aScans]:
    'Manage 314(a) scan settings, run 314(a) scans, view 314(a) scan history, and more',
};

import { CoreSourceChip } from 'app/modules/investigations/components/AiInvestigation/Chip/CoreSourceChip';
import { TYPE_TO_DATA_TYPE_MAP } from 'app/modules/investigations/components/AiInvestigation/constants';
import { useInternalSourceDetail } from 'app/modules/investigations/components/AiInvestigation/hooks';
import { AiInvestigationInternalSource } from 'app/modules/investigations/types/responses';
import { U21DataChip } from 'app/shared/u21-ui/components/dashboard';
import styled from 'styled-components';

interface OwnProps {
  sourceNumber: number;
  sourceData: AiInvestigationInternalSource;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
}

export const InternalSourceChip = ({
  sourceNumber,
  sourceData,
  isHovering,
  setIsHovering,
}: OwnProps) => {
  const { label, id } = useInternalSourceDetail(sourceData);

  return (
    <CoreSourceChip
      sourceNumber={sourceNumber}
      isHovering={isHovering}
      setIsHovering={setIsHovering}
      chip={
        <StyledContentChip
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          $isCurrHover={isHovering}
          type={TYPE_TO_DATA_TYPE_MAP[sourceData.data_type]}
          id={id ?? ''}
          label={label}
          variant="ghost"
        />
      }
    />
  );
};

const StyledContentChip = styled(U21DataChip)<{ $isCurrHover: boolean }>`
  background-color: ${(props) =>
    props.$isCurrHover ? props.theme.palette.action.hover : 'inherit'};
`;

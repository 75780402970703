import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  U21HelpTooltip,
  U21Loading,
  U21NoData,
  U21Select,
  U21Spacer,
  U21Table,
  U21TableBodyCellProps,
  U21TableColumnTypes,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types/responses';

// Selectors
import { selectTxnTableConfig } from 'app/shared/CustomConfig/selectors';

// Thunks

// Utils
import { toReadableMediaType } from 'app/modules/attachmentsRefresh/utils';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { TxnEntityTypes } from 'app/modules/transactions/constants';
import { AlertDispositionChipList } from 'app/modules/alerts/components/AlertDispositionChipList';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { TRANSACTION_COLUMN_CONFIG } from 'app/modules/transactions/columns';
import { useGetCheckImageTransactionById } from 'app/modules/transactions/queries/useGetCheckImageTransactionById';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

type AttachmentOptions = {
  value: number;
  text: string;
  description: string;
};

type FormatedInfo = {
  options: AttachmentOptions[];
  docInfo: Record<number, FullAttachmentResponse>;
  docTxnMap: Record<number, ShortTxnResponse>;
};

type OwnProps = {
  txnEvent: ShortTxnResponse;
  isOverlayMode: boolean;
  setParentOffsetCanvas?: (offsetCanvas: OffscreenCanvas) => void;
  setHasDocumentsToCompare?: (hasDocumentsToCompare: boolean) => void;
};

export const CheckImageComparisonModalBody = ({
  txnEvent,
  isOverlayMode,
  setParentOffsetCanvas,
  setHasDocumentsToCompare,
}: OwnProps) => {
  const [entityType, setEntityType] = useState(
    txnEvent.receiver_entity_external_id
      ? TxnEntityTypes.RECEIVER
      : TxnEntityTypes.SENDER,
  );
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<
    number | undefined
  >();
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const transactionEventTableConfig = useSelector(selectTxnTableConfig);

  const { data: checkImageTxnEvents, isPending } =
    useGetCheckImageTransactionById(
      entityType === TxnEntityTypes.RECEIVER
        ? txnEvent.receiver_entity_external_id
        : txnEvent.sender_entity_external_id,
    );

  const { options, docInfo, docTxnMap } = useMemo<FormatedInfo>(() => {
    const returnObj: FormatedInfo = { options: [], docInfo: {}, docTxnMap: {} };

    if (!checkImageTxnEvents) {
      return returnObj;
    }

    checkImageTxnEvents.forEach((txn) => {
      txn.documents.forEach((doc) => {
        returnObj.docTxnMap[doc.id] = txn;
        returnObj.docInfo[doc.id] = doc;
        returnObj.options.push({
          value: doc.id,
          text: `#${doc.id}: ${doc.name}`,
          description: `Media type: ${toReadableMediaType(doc.media_type)}`,
        });
      });
    });

    return returnObj;
  }, [checkImageTxnEvents]);

  useEffect(() => {
    setSelectedAttachmentId(options.length > 0 ? options[0].value : undefined);
    if (setHasDocumentsToCompare) setHasDocumentsToCompare(options.length > 0);
  }, [options, setHasDocumentsToCompare]);

  const columns = useMemo(
    () => [
      ...createTableColumnConfig<ShortTxnResponse>(
        transactionEventTableConfig,
        TRANSACTION_COLUMN_CONFIG,
      ),
      ...(hasReadAlertsPermission
        ? [
            {
              id: 'alert_ids',
              type: U21TableColumnTypes.LIST,
              accessor: 'alert_ids',
              Header: 'Alerts',
              disableSortBy: true,
              minWidth: 250,
              Cell: (
                props: U21TableBodyCellProps<ShortTxnResponse, number[]>,
              ) => {
                const { value } = props;
                return <AlertDispositionChipList alertIds={value} />;
              },
            },
          ]
        : []),
    ],
    [hasReadAlertsPermission, transactionEventTableConfig],
  );

  if (isPending) {
    return <StyledLoader loading />;
  }

  return (
    <ModalBodySpacer spacing={4}>
      <U21Spacer spacing={0}>
        <U21Spacer horizontal>
          <U21Typography variant="h4">Other Checks</U21Typography>
          <U21HelpTooltip
            help={`If you'd like to compare against all transactions, do it via selecting them through Transaction Analysis tab and selecting "Compare Images"`}
          />
        </U21Spacer>

        <U21Typography variant="body2" color="text.secondary">
          Select which checks you would like to compare against
        </U21Typography>
      </U21Spacer>
      <StyledU21Spacer spacing={2}>
        <U21Spacer horizontal>
          <StyledU21SelectEntity
            options={[
              {
                text: 'Sender entity',
                value: TxnEntityTypes.SENDER,
                disabled: !txnEvent.sender_entity_external_id,
              },
              {
                text: 'Receiver entity',
                value: TxnEntityTypes.RECEIVER,
                disabled: !txnEvent.receiver_entity_external_id,
              },
            ]}
            value={entityType}
            onChange={setEntityType}
            clearable={false}
          />
          <StyledU21SelectImage
            placeholder="Select an image"
            options={options}
            onChange={(id: number) => setSelectedAttachmentId(id)}
            value={selectedAttachmentId}
            clearable={false}
          />
        </U21Spacer>
        {checkImageTxnEvents && checkImageTxnEvents.length > 0 ? (
          <>
            {options.length > 0 && selectedAttachmentId ? (
              <DocumentDisplay
                isEditableImage
                file={docInfo[selectedAttachmentId]}
                isOverlayMode={isOverlayMode}
                setParentOffsetCanvas={setParentOffsetCanvas}
              />
            ) : (
              <U21NoData>There are no check images</U21NoData>
            )}
            {Object.keys(docTxnMap).length > 0 &&
              selectedAttachmentId &&
              docTxnMap[selectedAttachmentId] && (
                <U21Spacer>
                  <U21Typography variant="subtitle1">
                    Transaction: #{docTxnMap[selectedAttachmentId]?.id || -1}
                  </U21Typography>
                  <StyledU21Table
                    columns={columns}
                    data={[docTxnMap[selectedAttachmentId]]}
                  />
                </U21Spacer>
              )}
          </>
        ) : (
          <U21NoData>
            There is no valid transactions with check images
          </U21NoData>
        )}
      </StyledU21Spacer>
    </ModalBodySpacer>
  );
};

const StyledLoader = styled(U21Loading)`
  display: flex;
  flex-grow: 1;
`;

const ModalBodySpacer = styled(U21Spacer)`
  width: 50%;
  height: 100%;
`;

const StyledU21Spacer = styled(U21Spacer)`
  height: 100%;
  align-content: space-between;
`;

const StyledU21SelectEntity = styled(U21Select)`
  width: 200px;
`;
const StyledU21SelectImage = styled(U21Select)`
  width: 350px;
`;

const StyledU21Table = styled(U21Table<ShortTxnResponse>)`
  height: fit-content;
`;

import {
  U21Chip,
  U21MenuButton,
  U21Section,
  U21Spacer,
  U21Typography,
  U21TypographyIcon,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  LinkedEntitiesTable,
  LinkedInstrumentsTable,
} from 'app/modules/networkAnalysisRefresh/components/LinkedObjectsTables';
import pluralize from 'pluralize';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { IconCircleCheck, IconDots } from '@u21/tabler-icons';
import { truncate } from 'lodash';
import {
  BaseObjectType,
  LinkGroupItem,
  MergedEntity,
  MergedInstrument,
} from 'app/modules/networkAnalysisRefresh/types';
import {
  NODE_OR_CONNECTION_TYPE_TO_ICON,
  NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME,
} from 'app/modules/networkAnalysisRefresh/constants';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import {
  mergeEntities,
  mergeInstruments,
} from 'app/modules/networkAnalysisRefresh/helpers';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { toggleTableConfigSidebar } from 'app/modules/sidebar/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSidebarEntityID,
  selectSidebarInstrumentID,
} from 'app/modules/sidebar/selectors';
import CopyToClipboard from 'react-copy-to-clipboard';

interface LinkSectionProps {
  link: LinkGroupItem;
}

export const LinkSection = ({ link }: LinkSectionProps) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const {
    networkGroups,
    expandedSections,
    setExpandedSections,
    handleLinkSectionMounted,
    baseObjectType,
    fetchedEntities,
    fetchedInstruments,
  } = useNetworkAnalysisContext();

  useEffect(() => {
    return handleLinkSectionMounted(link.id, sectionRef);
  }, [handleLinkSectionMounted, link]);

  const mergedEntities: MergedEntity[] = useMemo(() => {
    if (baseObjectType === BaseObjectType.ENTITY) {
      return mergeEntities(link.objects, fetchedEntities, networkGroups);
    }
    return [];
  }, [networkGroups, link, baseObjectType, fetchedEntities]);

  const mergedInstruments: MergedInstrument[] = useMemo(() => {
    if (baseObjectType === BaseObjectType.INSTRUMENT) {
      return mergeInstruments(link.objects, fetchedInstruments, networkGroups);
    }
    return [];
  }, [networkGroups, link, baseObjectType, fetchedInstruments]);

  const sidebarEntityID = useSelector(selectSidebarEntityID);
  const sidebarInstrumentID = useSelector(selectSidebarInstrumentID);
  const highlighted = useMemo(() => {
    if (baseObjectType === BaseObjectType.ENTITY && sidebarEntityID) {
      return [sidebarEntityID];
    }
    if (baseObjectType === BaseObjectType.INSTRUMENT && sidebarInstrumentID) {
      return [sidebarInstrumentID];
    }
    return undefined;
  }, [sidebarEntityID, sidebarInstrumentID, baseObjectType]);

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Spacer horizontal align="center">
            <U21TypographyIcon variant="h4">
              {NODE_OR_CONNECTION_TYPE_TO_ICON[link.node_type]}
            </U21TypographyIcon>
            <span>
              {NODE_OR_CONNECTION_TYPE_TO_READABLE_NAME[link.node_type]}
            </span>
            :{' '}
            <StyledTypography variant="h4">
              {truncate(link.label, { length: 60 })}
            </StyledTypography>
            <U21Chip
              color={
                (mergedEntities.length || mergedInstruments.length) > 5
                  ? 'error'
                  : 'warning'
              }
              variant="ghost"
            >
              {pluralize(
                baseObjectType === BaseObjectType.ENTITY
                  ? 'Entity'
                  : 'Instrument',
                mergedEntities.length || mergedInstruments.length,
                true,
              )}
            </U21Chip>
          </U21Spacer>
        }
        collapsible
        collapsed={!expandedSections.has(link.id)}
        onToggleCollapse={(c) =>
          setExpandedSections((prev) => {
            const newSet = new Set(prev);
            if (c) {
              newSet.delete(link.id);
            } else {
              newSet.add(link.id);
            }
            return newSet;
          })
        }
        action={
          // TODO: support opening instrument sidebar via external id
          <LinkSectionActions
            tableConfigType={
              baseObjectType === BaseObjectType.ENTITY
                ? TableConfigType.ENTITY_TABLE
                : TableConfigType.INSTRUMENT_TABLE
            }
            hash={link.id}
          />
        }
      >
        {baseObjectType === BaseObjectType.ENTITY ? (
          <LinkedEntitiesTable
            entities={mergedEntities}
            highlighted={highlighted}
          />
        ) : (
          <LinkedInstrumentsTable
            instruments={mergedInstruments}
            highlighted={highlighted}
          />
        )}
      </U21Section>
    </div>
  );
};

export const LinkSectionActions = ({
  tableConfigType,
  hash,
}: {
  tableConfigType: TableConfigType;
  hash: string;
}) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const { pathname, search } = useLocation();
  const history = useHistory();
  return (
    <U21MenuButton
      buttonProps={{
        onClick: (e) => {
          e.stopPropagation();
        },
      }}
      items={[
        {
          text: 'Choose columns',
          onClick: (e) => {
            e.stopPropagation();
            dispatch(
              toggleTableConfigSidebar({
                tableConfigType,
              }),
            );
          },
        },
        {
          text: (
            <CopyToClipboard
              text={`${window.location.origin}${pathname}${search}#${hash}`}
              onCopy={() => {
                history.replace(`${pathname}${search}#${hash}`);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
            >
              <span>{copied ? 'Copied!' : 'Copy section URL'}</span>
            </CopyToClipboard>
          ),
          onClick: async (e) => {
            e.stopPropagation();
          },
        },
      ]}
    >
      <IconDots />
    </U21MenuButton>
  );
};

export const StyledTypography = styled(U21Typography)`
  text-transform: none;
`;

export const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${(props) => props.theme.palette.success.main};
`;

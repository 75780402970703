import { useQuery } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';
import { GetEntitiesResponse } from 'app/modules/entitiesRefresh/responses';
import { GetInstrumentsResponse } from 'app/modules/instruments/responses';
import { PaginationPayload } from 'app/shared/pagination/models';
import { GetEntitiesPayload } from 'app/modules/entitiesRefresh/requests';
import { GetInstrumentsPayload } from 'app/modules/instruments/requests';

const BASE_PAYLOAD: PaginationPayload = {
  limit: 1000,
  offset: 1,
  sort_direction: 'ascending',
  sort_column: 'created_at',
};

const BASE_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: Infinity,
};

export const useGetNetworkAnalysisEntities = (externalIds: string[]) => {
  return useQuery({
    queryKey: [
      ...NETWORK_ANALYSIS_QUERY_KEYS.getEntitiesForNetworkAnalysis(externalIds),
    ],
    queryFn: () => {
      return post<GetEntitiesResponse>('/entities/list', {
        ...BASE_PAYLOAD,
        entity_external_ids: externalIds,
      } satisfies GetEntitiesPayload);
    },
    enabled: externalIds.length > 0,
    ...BASE_QUERY_OPTIONS,
  });
};

export const useGetNetworkAnalysisInstruments = (externalIds: string[]) => {
  return useQuery({
    queryKey: [
      ...NETWORK_ANALYSIS_QUERY_KEYS.getInstrumentsForNetworkAnalysis(
        externalIds,
      ),
    ],
    queryFn: () => {
      return post<GetInstrumentsResponse>('/instruments/list', {
        ...BASE_PAYLOAD,
        instrument_external_ids: externalIds,
      } satisfies GetInstrumentsPayload);
    },
    enabled: externalIds.length > 0,
    ...BASE_QUERY_OPTIONS,
  });
};

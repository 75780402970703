import { GetInstrumentsFilters } from 'app/modules/instruments/requests';

import { post } from 'app/shared/utils/fetchr';
import { selectAgent } from 'app/modules/session/selectors';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

interface ExportInstrumentsPayload {
  export_type: 'INSTRUMENT_TABLE';
  export_ids: (string | number)[];
  agent_id: number;
  receive_email: boolean;
  filters: GetInstrumentsFilters;
  use_csv: boolean;
}

export const useExportInstruments = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectAgent);
  return useMutation({
    mutationFn: (
      payload: Omit<ExportInstrumentsPayload, 'agent_id' | 'export_type'>,
    ) =>
      post<Record<string, never>>('/data-explorer/export', {
        ...payload,
        agent_id: id,
        export_type: 'INSTRUMENT_TABLE',
      }),
    onSuccess: () => dispatch(sendSuccessToast('Generating your export...')),
    onError: () =>
      dispatch(
        sendErrorToast('Failed to export instruments. Please try again.'),
      ),
  });
};

import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { U21Table, U21TableColumn } from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MergedEntity,
  MergedInstrument,
} from 'app/modules/networkAnalysisRefresh/types';
import { useNetworkAnalysisContext } from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { createTableColumnConfig } from 'app/shared/utils/table';
import {
  selectEntityTableConfig,
  selectInstrumentTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { INSTRUMENT_COLUMN_CONFIG } from 'app/modules/instruments/columns';
import { getLinksColumn } from 'app/modules/networkAnalysisRefresh/helpers';

export const LinkedEntitiesTable = ({
  entities,
  highlighted,
}: {
  entities: MergedEntity[];
  highlighted?: (string | number)[];
}) => {
  const { data, networkGroups } = useNetworkAnalysisContext();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const entityTableConfig = useSelector(selectEntityTableConfig);

  const columns = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      getLinksColumn<MergedEntity>(
        data?.graph_result?.nodes,
        networkGroups,
        history,
        pathname,
        search,
      ),
      ...createTableColumnConfig<MergedEntity>(
        entityTableConfig,
        ENTITY_COLUMN_CONFIG as Record<
          string,
          Partial<U21TableColumn<MergedEntity, undefined, undefined>>
        >,
      ),
    ];
  }, [data, networkGroups, history, pathname, search, entityTableConfig]);

  return (
    <U21Table
      data={entities}
      columns={columns}
      onRowClick={(id) =>
        dispatch(
          toggleSidebar({
            type: SidebarComponentTypes.ENTITY,
            data: { id },
          }),
        )
      }
      defaultPageSize={10}
      highlighted={highlighted}
    />
  );
};

export const LinkedInstrumentsTable = ({
  instruments,
  highlighted,
}: {
  instruments: MergedInstrument[];
  highlighted?: (string | number)[];
}) => {
  const { data, networkGroups } = useNetworkAnalysisContext();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const instrumentTableConfig = useSelector(selectInstrumentTableConfig);

  const columns = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      getLinksColumn<MergedInstrument>(
        data?.graph_result?.nodes,
        networkGroups,
        history,
        pathname,
        search,
      ),
      ...createTableColumnConfig<MergedInstrument>(
        instrumentTableConfig,
        INSTRUMENT_COLUMN_CONFIG as Record<
          string,
          Partial<U21TableColumn<MergedInstrument, undefined, undefined>>
        >,
      ),
    ];
  }, [data, networkGroups, history, pathname, search, instrumentTableConfig]);

  return (
    <U21Table
      data={instruments}
      columns={columns}
      onRowClick={(id) =>
        dispatch(
          toggleSidebar({
            type: SidebarComponentTypes.INSTRUMENT,
            data: { id },
          }),
        )
      }
      defaultPageSize={10}
      highlighted={highlighted}
    />
  );
};

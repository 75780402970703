import {
  IconLock,
  IconFlag,
  IconUser,
  IconCreditCard,
  IconCoin,
} from '@u21/tabler-icons';
import { useGetActionEventDetail } from 'app/modules/actionEvents/queries/useGetActionEventDetail';
import { useGetAlert } from 'app/modules/alerts/queries/useGetAlert';
import { useEntityDetails } from 'app/modules/entitiesRefresh/queries/useEntityDetails';
import { useInstrumentDetails } from 'app/modules/instruments/queries/useInstrumentDetails';
import { AiInvestigationInternalSource } from 'app/modules/investigations/types/responses';
import { useGetTransactionById } from 'app/modules/transactions/queries/useGetTransactionById';
import { consoleError } from 'app/shared/utils/console';
import { ReactElement, useMemo } from 'react';

export const useInternalSourceDetail = (
  sourceData: AiInvestigationInternalSource,
): {
  icon: ReactElement | undefined;
  label: string;
  id: string | number | undefined;
} => {
  const { data: actionEventDetails } = useGetActionEventDetail(
    sourceData.data_type === 'ACTION_EVENT' ? sourceData.id : undefined,
  );

  const { alert } = useGetAlert(sourceData.id, {
    enabled: sourceData.data_type === 'ALERT',
  });

  const { data: entityDetails } = useEntityDetails(
    sourceData.external_id || '',
    undefined,
    sourceData.external_id !== undefined && sourceData.data_type === 'ENTITY',
  );

  const { data: instrumentDetails } = useInstrumentDetails(
    sourceData.external_id || '',
    undefined,
    sourceData.external_id !== undefined &&
      sourceData.data_type === 'INSTRUMENT',
  );

  const { data: txnDetails } = useGetTransactionById(
    sourceData.data_type === 'TRANSACTION' ? `${sourceData.id}` : undefined,
  );

  return useMemo(() => {
    switch (sourceData.data_type) {
      case 'ACTION_EVENT':
        return {
          icon: <IconLock />,
          label: `#${sourceData.id}`,
          id: actionEventDetails?.id,
        };
      case 'ALERT':
        return {
          icon: <IconFlag />,
          label: `#${sourceData.id}`,
          id: alert?.id,
        };
      case 'ENTITY':
        return {
          icon: <IconUser />,
          label:
            entityDetails?.external_id ||
            sourceData.external_id ||
            (entityDetails && entityDetails.id
              ? `#${entityDetails.id}`
              : 'Internal Entity'),
          id: entityDetails?.id,
        };
      case 'INSTRUMENT':
        return {
          icon: <IconCreditCard />,
          label: `#${sourceData.id}`,
          id: instrumentDetails?.id,
        };
      case 'TRANSACTION':
        return {
          icon: <IconCoin />,
          label: `#${sourceData.id}`,
          id: txnDetails?.id,
        };
      default: {
        const exhaustivenessCheck: never = sourceData.data_type;
        consoleError(
          `Unknown AI investigation source data value: ${exhaustivenessCheck}`,
        );
        return {
          icon: undefined,
          label: '',
          id: undefined,
        };
      }
    }
  }, [
    actionEventDetails?.id,
    alert?.id,
    entityDetails,
    instrumentDetails?.id,
    sourceData.data_type,
    sourceData.external_id,
    sourceData.id,
    txnDetails?.id,
  ]);
};

import { FullTxnResponse } from 'app/modules/transactions/types/responses';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';
import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectDLEnabledForTxnEventDetails } from 'app/shared/featureFlags/selectors';

export const useGetTransactionById = <TSelect = FullTxnResponse>(
  id?: string,
  select?: (data: FullTxnResponse) => TSelect,
) => {
  const useNewEndpoint = useSelector(selectDLEnabledForTxnEventDetails);

  const endpoint = useNewEndpoint
    ? `/txn-events/cached/${id}`
    : `/txn-events/${id}`;

  return useQuery({
    queryFn: () => get<FullTxnResponse>(endpoint),
    queryKey: [...TRANSACTION_QUERY_KEYS.getTransactionById(id), endpoint],
    select,
    enabled: !!id,
  });
};

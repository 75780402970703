import { useDispatch } from 'react-redux';

// Mutations
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Actions
import { sendErrorToast } from 'app/shared/toasts/actions';

// Utils
import { post } from 'app/shared/utils/fetchr';
import { CHECKLIST_QUERY_KEYS } from 'app/shared/customChecklist/queries/keys';
import { INVESTIGATION_QUERY_KEYS } from 'app/modules/investigations/queries/keys';

interface TriggerAiInvestigationPayload {
  article_id: number;
  custom_checklist_submission_id?: number;
}

export const manuallyTriggerAiInvestigation = (
  payload: TriggerAiInvestigationPayload,
): Promise<{ custom_checklist_submission_id: number }> =>
  post(`/ai-investigations/run`, payload);

export const useTriggerAiInvestigation = (
  payload: TriggerAiInvestigationPayload,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => manuallyTriggerAiInvestigation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CHECKLIST_QUERY_KEYS.getChecklistSubmission(
          payload.article_id,
        ),
      });
      queryClient.invalidateQueries({
        queryKey: INVESTIGATION_QUERY_KEYS.getAiInvestigationResult({
          article_id: payload.article_id,
          custom_checklist_submission_id:
            payload.custom_checklist_submission_id,
        }),
      });
    },
    onError: () =>
      dispatch(sendErrorToast('Failed to trigger AI Investigation.')),
  });
};

import { post } from 'app/shared/utils/fetchr';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GET_NETWORK_ANALYSIS_OBJECTS_BASE_QUERY_KEY,
  NETWORK_ANALYSIS_QUERY_KEYS,
} from 'app/modules/networkAnalysis/queries/keys';
import {
  BaseObjectType,
  NetworkAnalysisRefreshResponse,
} from 'app/modules/networkAnalysisRefresh/types';
import { useEffect } from 'react';

const getEntityNetworkAnalysis = async (entityExternalID: string) =>
  post<NetworkAnalysisRefreshResponse>(`/entities/network-analysis`, {
    entity_external_id: entityExternalID,
  });

const getInstrumentNetworkAnalysis = async (instrumentExternalID: string) =>
  post<NetworkAnalysisRefreshResponse>(`/instruments/network-analysis`, {
    instrument_external_id: instrumentExternalID,
  });

export const useGetNetworkAnalysis = (
  externalId: string,
  baseObjectType: BaseObjectType,
) => {
  const query = useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getNetworkAnalysisKey(
      externalId,
      baseObjectType,
    ),
    queryFn: () =>
      baseObjectType === BaseObjectType.ENTITY
        ? getEntityNetworkAnalysis(externalId)
        : getInstrumentNetworkAnalysis(externalId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (query.status === 'success') {
      queryClient.refetchQueries({
        queryKey: GET_NETWORK_ANALYSIS_OBJECTS_BASE_QUERY_KEY,
      });
    }
  }, [query.status, queryClient]);

  return query;
};

import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  U21Spacer,
  U21Alert,
  U21Chip,
  U21Typography,
  U21Button,
  U21ChipColor,
} from 'app/shared/u21-ui/components';
import { IconFlagSparkle } from 'app/shared/components/Icons/IconFlagSparkle';

import { DISPOSITION_TO_READABLE } from 'app/shared/utils/disposition';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { FROM_AI_COPILOT } from 'app/modules/alertScore/constants';
import { useShapValues } from 'app/modules/alertScore/useShapValues';
import { selectAlert } from 'app/modules/alerts/selectors';
import { getParameterDisplay } from 'app/modules/alertScore/utils/getParameterDisplay';
import { TAB_PATHS } from 'app/modules/alerts/components/AlertDetails/Tabs/constants';

export const AlertScoreAiCopilotBanner = () => {
  // Data fetching
  const { id: alertId } = useParams<{ id: string }>();
  const alert = useSelector(selectAlert);
  const alertScore = alert.alert_score;
  const { data: shapValuesData } = useShapValues(alertId, !!alertScore);

  let widgetColor: U21ChipColor = 'info';
  if (alertScore?.risk) {
    widgetColor = 'error';
  }

  const textColor = `${widgetColor}.darker` as const;

  return (
    <U21Alert
      title={
        <U21Spacer horizontal spacing={2}>
          <U21Spacer horizontal>
            <U21Typography color={textColor} variant="subtitle2">
              Alert Score:
            </U21Typography>
            <U21Chip color={widgetColor}>
              {alertScore ? Math.round(alertScore.score * 100) : 'N/A'}
            </U21Chip>
          </U21Spacer>
          <U21Spacer horizontal>
            <U21Typography color={textColor} variant="subtitle2">
              Suggested Disposition:
            </U21Typography>
            {alertScore ? (
              <U21Chip color={widgetColor}>
                {alertScore.risk
                  ? DISPOSITION_TO_READABLE.TRUE_POSITIVE
                  : DISPOSITION_TO_READABLE.FALSE_POSITIVE}
              </U21Chip>
            ) : (
              <U21Chip color={widgetColor}>N/A</U21Chip>
            )}
          </U21Spacer>
        </U21Spacer>
      }
      Icon={IconFlagSparkle}
      severity={widgetColor === 'info' ? 'normal' : widgetColor}
      collapsible
    >
      {shapValuesData && shapValuesData.narratives && (
        <ReasonsDiv>
          <U21Typography color={textColor} variant="subtitle2">
            Top reasons
          </U21Typography>
          <StyledUl>
            {Object.values(shapValuesData.narratives)
              .slice(0, 3)
              .map((narrative) => {
                const reParameter = /\{\{([^{}]*)\}\}/g;
                const narrativeSplit = narrative.template.split(reParameter);

                return (
                  <li key={narrative.template}>
                    <U21Spacer horizontal spacing={0}>
                      <U21Typography color={textColor} variant="body2">
                        {narrativeSplit.map((partialNarrativeStr) => {
                          const parameterValue =
                            shapValuesData.narrative_parameters?.[
                              partialNarrativeStr
                            ];
                          if (parameterValue !== undefined) {
                            const parameterType =
                              shapValuesData.narratives_data_types?.[
                                partialNarrativeStr
                              ];
                            const display = getParameterDisplay(
                              parameterValue,
                              parameterType,
                            );
                            return (
                              <Fragment
                                key={
                                  Array.isArray(display)
                                    ? display.toString()
                                    : display
                                }
                              >
                                {display}
                              </Fragment>
                            );
                          }
                          return partialNarrativeStr;
                        })}
                      </U21Typography>
                    </U21Spacer>
                  </li>
                );
              })}
          </StyledUl>
        </ReasonsDiv>
      )}
      {alertScore && (
        <U21Button
          color={widgetColor}
          variant="text"
          to={{
            pathname: ROUTES_MAP.alertsIdTab.path
              .replace(':id', alertId)
              .replace(':tab?', TAB_PATHS.ALERT_SCORE),
            state: FROM_AI_COPILOT,
          }}
        >
          Learn More
        </U21Button>
      )}
    </U21Alert>
  );
};

const StyledUl = styled.ul`
  margin-left: 1rem;
  margin-top: 1rem;
`;

const ReasonsDiv = styled.div`
  margin-top: 1.5rem;
`;

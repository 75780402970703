import { ACTION_EVENT_QUERY_KEYS } from 'app/modules/actionEvents/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { selectDLEnabledForActionEventDetail } from 'app/shared/featureFlags/selectors';

export const useGetActionEventDetail = (id?: number) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const useNewEndpoint = useSelector(selectDLEnabledForActionEventDetail);
  const endpoint = useNewEndpoint
    ? `/action-events/cached/${id}`
    : `/action-events/${id}`;

  return useQuery({
    queryFn: () => get<ShortActionEventResponse>(endpoint),
    queryKey: [...ACTION_EVENT_QUERY_KEYS.getActionEventDetail(id!), endpoint],
    enabled: id !== undefined && hasReadEventsPermission && id > 0,
  });
};

import {
  U21Alert,
  U21Button,
  U21Chip,
  U21Loading,
  U21MenuButton,
  U21NoData,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filters } from 'app/modules/networkAnalysisRefresh/components/Filters';
import { resetSelectedNodesAndEdges } from 'app/modules/networkAnalysis/helpers';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { useHistory, useLocation } from 'react-router';
import {
  LINK_ANALYSIS_LINK_TYPES,
  TRANSACTIONS_SECTION_HASH,
  ELEMENT_KEYS_TO_UPDATE,
  ENTITY_RELATIONSHIPS_SECTION_HASH,
  INSTRUMENT_RELATIONSHIPS_SECTION_HASH,
} from 'app/modules/networkAnalysis/constants';
import {
  U21NetworkGraph,
  U21NetworkGraphProps,
} from 'app/shared/components/Graphs/U21NetworkGraph';
import { Core, EdgeSingular, EventObject, NodeSingular } from 'cytoscape';
import {
  IconAffiliate,
  IconCircleDot,
  IconDots,
  IconGridDots,
  IconRefresh,
  IconSpace,
  IconSpaceOff,
} from '@u21/tabler-icons';
import { NetworkLinks } from 'app/modules/networkAnalysisRefresh/components/NetworkLinks';
import {
  CONCENTRIC_LAYOUT_OPTIONS,
  GRID_LAYOUT_OPTIONS,
} from 'app/shared/components/Graphs/constants';
import { useQueueNetworkAnalysis } from 'app/modules/networkAnalysisRefresh/queries/useQueueNetworkAnalysis';
import { NetworkAnalysisStatus } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysisStatus';
import {
  selectNodes,
  getBaseNodeId,
  getCytoscapeOptions,
} from 'app/modules/networkAnalysisRefresh/helpers';
import {
  BaseObjectType,
  ConnectionType,
  EntityNodeData,
  InstrumentNodeData,
  NodeType,
} from 'app/modules/networkAnalysisRefresh/types';
import pluralize from 'pluralize';
import styled from 'styled-components';
import {
  ShortEntityResponse,
  FullEntityResponse,
} from 'app/modules/entities/types';
import {
  FullTxnInstrumentResponse,
  ShortTxnInstrumentResponse,
} from 'app/modules/txnInstruments/types';
import {
  NetworkAnalysisProvider,
  useNetworkAnalysisContext,
} from 'app/modules/networkAnalysisRefresh/contexts/NetworkAnalysisContext';
import { usePollNetworkAnalysisStatus } from 'app/modules/networkAnalysisRefresh/queries/usePollNetworkAnalysisStatus';
import { SelectCaseObjectsModal } from 'app/modules/networkAnalysisRefresh/components/SelectCaseObjectsModal';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { BASE_OBJECT_TYPE_TO_NETWORK_ANALYSIS_TYPE } from 'app/modules/networkAnalysisRefresh/constants';
import { useGetNetworkAnalysisSettings } from 'app/modules/networkAnalysisSettings/queries/useGetNetworkAnalysisSettings';
import { NewFeatureAlert } from 'app/shared/components/GaBanner/NewFeatureAlert';
import { FeatureFlag } from 'app/shared/featureFlags/models';

interface EntityNetworkAnalysisProps {
  baseObject: ShortEntityResponse | FullEntityResponse;
  baseObjectType: BaseObjectType.ENTITY;
}

interface InstrumentNetworkAnalysisProps {
  baseObject: ShortTxnInstrumentResponse | FullTxnInstrumentResponse;
  baseObjectType: BaseObjectType.INSTRUMENT;
}

const NetworkAnalysisInner = () => {
  const {
    externalId,
    baseObjectType,
    data,
    isLoading,
    elements,
    totals: {
      totalObjects,
      totalLinks,
      totalTransactions,
      totalEntityRelationships,
      totalInstrumentRelationships,
    },
    setExpandedSections,
    hashReferences,
    fetchedInstruments,
  } = useNetworkAnalysisContext();

  const { hash, pathname, search } = useLocation();
  const { mutate: queueNetworkAnalysis, isPending: isQueuePending } =
    useQueueNetworkAnalysis(externalId, baseObjectType);
  const { data: statusData } = usePollNetworkAnalysisStatus(
    externalId,
    baseObjectType,
  );

  const reduxDispatch = useDispatch();
  const history = useHistory();

  const [spaceNodes, setSpaceNodes] = useState<boolean>(
    Object.keys(data?.graph_result?.nodes ?? {}).length < 30,
  );
  const [isGridLayout, setIsGridLayout] = useState<boolean>(false);

  const baseNodeId = useMemo(() => getBaseNodeId(data), [data]);

  const handleGraphClick: U21NetworkGraphProps['handleGraphClick'] =
    useCallback(
      (e: EventObject, cy: Core | null) => {
        const group = e.target?.group?.();
        const isMetaClick = e.originalEvent.ctrlKey || e.originalEvent.metaKey;
        const urlBase = `${pathname}${search}`;
        if (group === 'nodes') {
          const node: NodeSingular = e.target;
          const { id, node_type: type, ...rest } = node.data();
          resetSelectedNodesAndEdges(cy);
          selectNodes(node, baseNodeId, baseObjectType);
          // cmd/ctrl click should open sidebar or scroll to related link section
          if (isMetaClick) {
            if (
              baseObjectType === BaseObjectType.ENTITY &&
              type === NodeType.ENTITY
            ) {
              const entityNodeData: EntityNodeData = rest;
              reduxDispatch(
                toggleSidebar({
                  type: SidebarComponentTypes.ENTITY,
                  data: {
                    id: 0,
                    externalId: entityNodeData.external_id,
                  },
                }),
              );
            } else if (
              baseObjectType === BaseObjectType.INSTRUMENT &&
              type === NodeType.INSTRUMENT
            ) {
              const instrumentNodeData: InstrumentNodeData = rest;
              const extId = instrumentNodeData.external_id;
              const instrument = fetchedInstruments[extId];
              if (instrument) {
                reduxDispatch(
                  toggleSidebar({
                    type: SidebarComponentTypes.INSTRUMENT,
                    data: { id: instrument.id },
                  }),
                );
              }
            } else {
              const linkHash =
                node.data('type') === LINK_ANALYSIS_LINK_TYPES.TRANSACTION
                  ? TRANSACTIONS_SECTION_HASH
                  : id;
              history.replace(`${urlBase}#${linkHash}`);
            }
          }
        }
        // if target is a transaction or relationship edge, highlight it and its nodes
        else if (group === 'edges') {
          resetSelectedNodesAndEdges(cy);
          const edge: EdgeSingular = e.target;
          edge.data('selected', true);
          for (const el of edge.connectedNodes()) {
            el.data('selected', true);
          }
          if (isMetaClick) {
            const edgeType = edge.data('edge_type');
            if (edgeType === ConnectionType.TRANSACTION) {
              history.replace(`${urlBase}#${TRANSACTIONS_SECTION_HASH}`);
            } else if (edgeType === ConnectionType.ENTITY_RELATIONSHIP) {
              history.replace(
                `${urlBase}#${ENTITY_RELATIONSHIPS_SECTION_HASH}`,
              );
            } else if (
              baseObjectType === BaseObjectType.ENTITY &&
              edgeType === NodeType.INSTRUMENT
            ) {
              history.replace(
                `${urlBase}#${INSTRUMENT_RELATIONSHIPS_SECTION_HASH}`,
              );
            } else if (edgeType === ConnectionType.INSTRUMENT_RELATIONSHIP) {
              history.replace(
                `${urlBase}#${INSTRUMENT_RELATIONSHIPS_SECTION_HASH}`,
              );
            } else if (
              baseObjectType === BaseObjectType.INSTRUMENT &&
              edgeType === NodeType.ENTITY
            ) {
              history.replace(
                `${urlBase}#${ENTITY_RELATIONSHIPS_SECTION_HASH}`,
              );
            }
          }
        }
      },
      [
        pathname,
        search,
        baseNodeId,
        baseObjectType,
        reduxDispatch,
        fetchedInstruments,
        history,
      ],
    );

  /**
   * Effect to scroll to url-hash link element
   */
  useEffect(() => {
    if (hash) {
      const parsedHash = hash.slice(1).replaceAll('%20', ' ');
      setExpandedSections((prev) => {
        const newSet = new Set(prev);
        newSet.add(parsedHash);
        return newSet;
      });
      const sectionToScroll = hashReferences[parsedHash];
      setTimeout(() => {
        if (sectionToScroll && sectionToScroll.current) {
          sectionToScroll.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    }
  }, [hash, hashReferences, setExpandedSections]);

  const [showHints, setShowHints] = useState<boolean>(false);
  const objectHasLinks: boolean = useMemo(() => {
    return !!data && Object.keys(data.graph_result.nodes).length > 0;
  }, [data]);

  const layoutOptions = useMemo(
    () => ({
      ...(isGridLayout ? GRID_LAYOUT_OPTIONS : CONCENTRIC_LAYOUT_OPTIONS),
      nodeDimensionsIncludeLabels: spaceNodes,
    }),
    [spaceNodes, isGridLayout],
  );

  const graphActions: U21NetworkGraphProps['actions'] = useMemo(
    () => [
      {
        key: 'spaceNodes',
        children: spaceNodes ? <IconSpaceOff /> : <IconSpace />,
        tooltip: spaceNodes
          ? 'Remove additional spacing between nodes'
          : 'Add additional spacing between nodes',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setSpaceNodes((prev) => !prev),
      },
      {
        key: 'alterLayout',
        children: isGridLayout ? <IconCircleDot /> : <IconGridDots />,
        tooltip: isGridLayout ? 'Use concentric layout' : 'Use grid layout',
        variant: 'outlined' as const,
        color: 'primary' as const,
        onClick: () => setIsGridLayout((prev) => !prev),
      },
    ],
    [spaceNodes, isGridLayout],
  );

  const cytoscapeOptions = useMemo(
    () => getCytoscapeOptions(baseObjectType),
    [baseObjectType],
  );

  const [selectCaseObjectsModalOpen, setSelectCaseObjectsModalOpen] =
    useState<boolean>(false);
  const { data: networkAnalysisSettings } = useGetNetworkAnalysisSettings();

  const configIsDirty = useMemo(() => {
    if (!networkAnalysisSettings || !statusData?.finished_at) return false;
    const config = networkAnalysisSettings.configs.find(
      (c) =>
        c.type === BASE_OBJECT_TYPE_TO_NETWORK_ANALYSIS_TYPE[baseObjectType],
    );
    if (
      config?.updated_at &&
      new Date(config.updated_at) > new Date(statusData.finished_at)
    )
      return true;
    return false;
  }, [networkAnalysisSettings, statusData?.finished_at, baseObjectType]);

  return (
    <U21Spacer spacing={4}>
      <U21Section
        title={
          <U21Spacer horizontal align="center" wrap>
            <span>Network Analysis</span>
            {objectHasLinks && (
              <U21Spacer horizontal align="center" wrap>
                <U21Chip>{pluralize('Link', totalLinks, true)}</U21Chip>
                <StyledChip>
                  {pluralize(
                    'Entity relationship',
                    totalEntityRelationships,
                    true,
                  )}
                </StyledChip>
                <StyledChip>
                  {pluralize(
                    'Instrument relationship',
                    totalInstrumentRelationships,
                    true,
                  )}
                </StyledChip>
                <U21Chip>
                  {pluralize('Transaction', totalTransactions, true)}
                </U21Chip>
                <StyledChip>
                  {pluralize(`Linked ${baseObjectType}`, totalObjects, true)}
                </StyledChip>
              </U21Spacer>
            )}
          </U21Spacer>
        }
        titleIcon={<IconAffiliate />}
        action={
          <U21Spacer horizontal align="center">
            <U21Button
              onClick={() => queueNetworkAnalysis(externalId)}
              loading={isQueuePending}
              disabled={
                statusData?.status === 'QUEUED' ||
                statusData?.status === 'IN_PROGRESS'
              }
              tooltip={(() => {
                if (statusData?.status === 'QUEUED') {
                  return 'Analysis already queued';
                }
                if (statusData?.status === 'IN_PROGRESS') {
                  return 'Analysis already in progress';
                }
                return undefined;
              })()}
              size="small"
              startIcon={<IconRefresh />}
            >
              Run New Analysis
            </U21Button>
            {objectHasLinks && baseObjectType === BaseObjectType.ENTITY && (
              <U21MenuButton
                buttonProps={{
                  size: 'small',
                  disabled:
                    statusData?.status === 'QUEUED' ||
                    statusData?.status === 'IN_PROGRESS',
                }}
                items={[
                  {
                    text: 'Create case',
                    onClick: () => {
                      setSelectCaseObjectsModalOpen(true);
                    },
                  },
                ]}
              >
                <IconDots />
              </U21MenuButton>
            )}
          </U21Spacer>
        }
      >
        <U21Spacer spacing={2}>
          {baseObjectType === BaseObjectType.ENTITY && (
            <NewFeatureAlert
              featureName="NETWORK_ANALYSIS"
              gaDateFeatureFlag={FeatureFlag.NETWORK_ANALYSIS_GA}
              displayName="network analysis"
              description="We've revamped our Network Analysis experience. You can now view more links, such as relationships and custom links, alongside with an improved layout."
            />
          )}
          {configIsDirty && (
            <U21Alert severity="warning">
              Your network analysis settings have changed since this analysis
              was last run. It is recommended to run a new analysis.{' '}
              <U21Button
                variant="text"
                href={ROUTES_MAP.networkAnalysisSettingsClassifier.path.replace(
                  ':classifier',
                  BASE_OBJECT_TYPE_TO_NETWORK_ANALYSIS_TYPE[baseObjectType],
                )}
              >
                You can view your network analysis settings here.
              </U21Button>
            </U21Alert>
          )}
          <NetworkAnalysisStatus
            externalId={externalId}
            baseObjectType={baseObjectType}
          />
          {!!data && objectHasLinks && <Filters />}
          {isLoading ? (
            <U21Loading loading />
          ) : (
            <>
              {(() => {
                const pluralizedBaseObjectType = pluralize(baseObjectType, 2);
                const otherObject =
                  baseObjectType === BaseObjectType.ENTITY
                    ? 'instrument'
                    : 'entity';
                if (objectHasLinks) {
                  return (
                    <>
                      {!showHints && (
                        <U21Typography variant="body2">
                          Need some help?{' '}
                          <U21Button
                            color="primary"
                            variant="text"
                            onClick={() => setShowHints(true)}
                          >
                            Show me how to use this graph
                          </U21Button>
                          .
                        </U21Typography>
                      )}
                      <U21Alert
                        hidden={!showHints}
                        severity="info"
                        title="How to use this graph:"
                        onClose={() => {
                          setShowHints(false);
                        }}
                      >
                        <U21Spacer>
                          <U21Typography variant="body2">
                            This graph allows you to visualize the connections
                            between {pluralizedBaseObjectType}. You will find
                            the {pluralizedBaseObjectType} that the base{' '}
                            {baseObjectType} has transacted with, the{' '}
                            {baseObjectType}&apos;s relationships, and
                            overlapping data points, which can be customized in{' '}
                            <U21Button
                              href={ROUTES_MAP.networkAnalysisSettingsClassifier.path.replace(
                                ':classifier',
                                BASE_OBJECT_TYPE_TO_NETWORK_ANALYSIS_TYPE[
                                  baseObjectType
                                ],
                              )}
                              variant="text"
                              color="primary"
                            >
                              network analysis settings
                            </U21Button>
                            .
                          </U21Typography>
                          <U21Typography variant="body2">
                            Some connections are shown with a dashed line to
                            indicate that they are not direct connections. A
                            dashed transaction line indicates at least one of
                            the two connected {pluralizedBaseObjectType} were
                            neither sending nor receiving the money, but rather
                            had a directionality of &apos;other&apos;.
                            Relationship connections shown with a dashed line
                            indicate the connection between the linked{' '}
                            {otherObject} is not from a relationship, but from
                            shared data point between that {otherObject} and
                            other {pluralize(otherObject, 2)} that the{' '}
                            {baseObjectType} is related to.
                          </U21Typography>
                          <U21Spacer spacing={0}>
                            <U21Typography variant="body2">
                              Tips for interacting with the graph:
                            </U21Typography>
                            <U21Typography variant="body2">
                              <li>
                                <b>Command/control + click</b> an{' '}
                                {baseObjectType} to open its details in a
                                sidebar.
                              </li>
                            </U21Typography>
                            <U21Typography variant="body2">
                              <li>
                                <b>Command/control + click</b> a link,
                                transaction or relationship to scroll to the
                                relevant section below the graph.
                              </li>
                            </U21Typography>
                            <U21Typography variant="body2">
                              <li>
                                <b>Command/control + scroll</b> or pinch to zoom
                                in and out.
                              </li>
                            </U21Typography>
                          </U21Spacer>
                        </U21Spacer>
                      </U21Alert>
                    </>
                  );
                }
                if (data) {
                  return (
                    <U21Alert severity="info">
                      This {baseObjectType} has no connections. Please run a new
                      analysis if this is unexpected.
                    </U21Alert>
                  );
                }
                return null;
              })()}
              {data?.graph_result ? (
                <U21NetworkGraph
                  cytoscapeOptions={cytoscapeOptions}
                  elements={elements}
                  elementKeysToUpdate={ELEMENT_KEYS_TO_UPDATE}
                  handleGraphClick={handleGraphClick}
                  layoutOptions={layoutOptions}
                  actions={graphActions}
                />
              ) : (
                <U21NoData>
                  This {baseObjectType} has no results. Try running a new
                  analysis.
                </U21NoData>
              )}
            </>
          )}
        </U21Spacer>
      </U21Section>
      {!!data && <NetworkLinks />}
      <SelectCaseObjectsModal
        selectCaseObjectsModalOpen={selectCaseObjectsModalOpen}
        setSelectCaseObjectsModalOpen={setSelectCaseObjectsModalOpen}
      />
    </U21Spacer>
  );
};

const StyledChip = styled(U21Chip)`
  text-transform: none;
`;

export const NetworkAnalysis = ({
  baseObject,
  baseObjectType,
}: EntityNetworkAnalysisProps | InstrumentNetworkAnalysisProps) => {
  return (
    <NetworkAnalysisProvider
      externalId={baseObject.external_id}
      baseObjectType={baseObjectType}
    >
      <NetworkAnalysisInner />
    </NetworkAnalysisProvider>
  );
};

import { CHIP_PINK } from 'app/modules/investigations/components/AiInvestigation/constants';
import {
  U21Chip,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface OwnProps {
  sourceNumber: number;
  isHovering: boolean;
  setIsHovering: (isHovering: boolean) => void;
  chip: ReactNode;
}

export const CoreSourceChip = ({
  sourceNumber,
  isHovering,
  chip,
  setIsHovering,
}: OwnProps) => {
  return (
    <StyledSpacer horizontal>
      <StyledNumberChip
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        $isCurrHover={isHovering}
        rounded
        variant="outlined"
        aria-label="sources redirect index chip"
      >
        <StyledTypography variant="caption" color="text.secondary">
          {sourceNumber}
        </StyledTypography>
      </StyledNumberChip>

      {chip}
    </StyledSpacer>
  );
};

const StyledSpacer = styled(U21Spacer)`
  margin: 8px;
  margin-left: 4px;
`;

const StyledNumberChip = styled(U21Chip)<{ $isCurrHover: boolean }>`
  cursor: ${(props) => (props.$isCurrHover ? 'pointer' : 'inherit')};
  background-color: ${(props) => (props.$isCurrHover ? CHIP_PINK : 'white')};
`;

const StyledTypography = styled(U21Typography)`
  .MuiTypography-root {
    font-size: 10px;
  }
`;

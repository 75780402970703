import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { CASE_QUERY_KEYS } from 'app/modules/cases/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { InvestigationActionPayload } from 'app/modules/investigations/types/requests';
import { selectDLEnabledForCaseDetail } from 'app/shared/featureFlags/selectors';

export const useReassignCase = (ids: number[]) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const useNewEndpoint = useSelector(selectDLEnabledForCaseDetail);

  return useMutation({
    mutationFn: (agentID: number) =>
      post('/investigations/action', {
        object_ids: ids,
        object_type: 'CASE',
        agent_id: agentID,
      } satisfies InvestigationActionPayload),
    onError: () =>
      dispatch(sendErrorToast('Failed to reassign case. Please try again.')),
    onSuccess: () => {
      dispatch(sendSuccessToast('Successfully reassigned case.'));
      ids.forEach((i) => {
        queryClient.invalidateQueries({
          queryKey: CASE_QUERY_KEYS.getCase(i, useNewEndpoint),
        });
      });
    },
  });
};

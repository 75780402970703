import {
  AllNativeFields,
  DataSettingFieldType,
  EntityNativeFields,
  InstrumentNativeFields,
} from 'app/modules/dataSettings/responses';
import { NODE_OR_CONNECTION_TYPE_TO_ICON } from 'app/modules/networkAnalysisRefresh/constants';
import { NetworkAnalysisType } from 'app/modules/networkAnalysisSettings/types';

export const MAX_MATCHABLE_FIELDS = 10;

export const MATCHABLE_NATIVE_FIELDS_BY_TYPE: Record<
  NetworkAnalysisType,
  AllNativeFields[]
> = {
  [NetworkAnalysisType.ENTITY]: [
    EntityNativeFields.ADDRESSES,
    EntityNativeFields.CLIENT_FINGERPRINTS,
    EntityNativeFields.EMAIL_ADDRESSES,
    EntityNativeFields.IP_ADDRESSES,
    EntityNativeFields.PHONE_NUMBERS,
    EntityNativeFields.PHYSICAL_IDS,
    EntityNativeFields.SSN,
  ],
  [NetworkAnalysisType.INSTRUMENT]: [
    InstrumentNativeFields.ADDRESSES,
    InstrumentNativeFields.CLIENT_FINGERPRINTS,
    InstrumentNativeFields.IP_ADDRESSES,
    EntityNativeFields.SSN,
  ],
};

export const MATCHABLE_NATIVE_FIELDS = Object.values(
  MATCHABLE_NATIVE_FIELDS_BY_TYPE,
).flat();

export const MATCHABLE_NATIVE_FIELD_TO_ICON = {
  [EntityNativeFields.ADDRESSES]: NODE_OR_CONNECTION_TYPE_TO_ICON.address,
  [EntityNativeFields.CLIENT_FINGERPRINTS]:
    NODE_OR_CONNECTION_TYPE_TO_ICON.client_fingerprint,
  [EntityNativeFields.EMAIL_ADDRESSES]:
    NODE_OR_CONNECTION_TYPE_TO_ICON.email_address,
  [EntityNativeFields.IP_ADDRESSES]: NODE_OR_CONNECTION_TYPE_TO_ICON.ip_address,
  [EntityNativeFields.PHONE_NUMBERS]:
    NODE_OR_CONNECTION_TYPE_TO_ICON.phone_number,
  [EntityNativeFields.PHYSICAL_IDS]:
    NODE_OR_CONNECTION_TYPE_TO_ICON.physical_id,
  [EntityNativeFields.SSN]: NODE_OR_CONNECTION_TYPE_TO_ICON.ssn,
};

export const MATCHABLE_CUSTOM_DATA_TYPES: Set<DataSettingFieldType> = new Set([
  DataSettingFieldType.TEXT,
  DataSettingFieldType.NUMBER,
]);

export const NETWORK_ANALYSIS_SETTINGS_QUERY_KEY = ['networkAnalysisSettings'];

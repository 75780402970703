import { NetworkAnalysisLegacy } from 'app/modules/networkAnalysis/components/NetworkAnalysisLegacy';
import { NetworkAnalysis } from 'app/modules/networkAnalysisRefresh/components/NetworkAnalysis';
import { NetworkAnalysisProps } from 'app/modules/networkAnalysis/types';
import { useSelector } from 'react-redux';
import { BaseObjectType } from 'app/modules/networkAnalysisRefresh/types';
import { selectNewFeatureEnabled } from 'app/shared/components/GaBanner/selector';
import { FeatureFlag } from 'app/shared/featureFlags/models';

export const NetworkAnalysisWrapper = ({ entity }: NetworkAnalysisProps) => {
  const linkAnalysisOnDeltaLake = useSelector((state) =>
    selectNewFeatureEnabled(
      state,
      'NETWORK_ANALYSIS',
      FeatureFlag.CM_DELTA_LAKE_ENTITY_NETWORK_ANALYSIS,
      FeatureFlag.NETWORK_ANALYSIS_GA,
    ),
  );

  return linkAnalysisOnDeltaLake ? (
    <NetworkAnalysis
      baseObject={entity}
      baseObjectType={BaseObjectType.ENTITY}
    />
  ) : (
    <NetworkAnalysisLegacy entity={entity} />
  );
};

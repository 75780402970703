import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import { U21Chip } from 'app/shared/u21-ui/components';
import {
  IconBriefcase,
  IconBuildingBank,
  IconClipboardList,
  IconFileDescription,
  IconFlag,
  IconKey,
  IconMapPin,
  IconTag,
  IconUser,
  IconUsers,
  IconUserX,
  IconRobot,
  IconScale,
  IconCash,
  IconReportMoney,
  IconCalendarTime,
  IconCalendar,
} from '@u21/tabler-icons';
import { Unreachable } from 'app/shared/components/Unreachable';

// Models
import {
  ActorChipProps,
  AssetChipComponentProps,
  AssetsChipProps,
  BasicChipProps,
  DateChipProps,
  DeadlineTypeChipProps,
  QueueChipProps,
} from 'app/modules/auditService/types';

// Selectors
import {
  selectAgent,
  selectHasEditQueuesPermission,
  selectHasReadAgentsPermissions,
  selectHasReadSARsPermissions,
  selectHasReadRulesPermission,
  selectHasReadRiskRatingsPermission,
  selectHasReadAlertsPermission,
  selectHasReadCasesPermissions,
  selectHasReadTeamsPermission,
} from 'app/modules/session/selectors';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { QUEUE_ATTRS } from 'app/modules/queues/constants';
import { DeadlineType } from 'app/modules/deadlines/models';
import { formatDatetime } from 'app/shared/utils/date';
import { PERMISSION_NAMES } from 'app/modules/permissions/constants';
import { SUPPORT_EMAIL } from 'app/shared/constants/contacts';
import { U21DataChip } from 'app/shared/u21-ui/components/dashboard';
import { U21DataChipType } from 'app/shared/u21-ui/components/dashboard/dataChip/U21DataChip';

const missingAssetTooltipCopy = `We are unable to fetch details for this entry. \
If this persists, please contact ${SUPPORT_EMAIL} for more information`;

/**
 * Utility which converts `name` to a standardize string and
 * provides a valid tooltip depending on the status of isMissing
 * @param props chip props
 */
function useMissingAwareProps<T extends BasicChipProps>(
  props: T,
): T & { tooltip: string | null } {
  const { id, isMissing } = props;
  if (!!isMissing === true) {
    const propsOfT = {
      ...props,
      name: `ID: ${id}`,
    } satisfies T;
    return {
      ...propsOfT,
      tooltip: missingAssetTooltipCopy,
    };
  }
  return {
    ...props,
    tooltip: null,
  };
}

export const AssetChip = (props: AssetChipComponentProps) => {
  const { type } = props;
  if (type === 'generic') {
    const { text } = props;
    return <StyledChip>{text}</StyledChip>;
  }

  switch (type) {
    case 'api':
      return <APIChip />;
    case 'action_event':
      return <ActionEventChip {...props} />;
    case 'agent':
      return <AgentChip {...props} />;
    case 'alert':
      return <AlertChip {...props} />;
    case 'attachment':
      return <AttachmentChip {...props} />;
    case 'case':
      return <CaseChip {...props} />;
    case 'date':
      return <DateChip {...props} />;
    case 'deadline_internal':
      return <DeadlineTypeChip {...props} />;
    case 'disposition':
      return <DispositionChip {...props} />;
    case 'entity':
      return <EntityChip {...props} />;
    case 'fincen_ctr':
      return <FincenCtrChip {...props} />;
    case 'goaml':
      return <GoAMLChip {...props} />;
    case 'instrument':
      return <InstrumentChip {...props} />;
    case 'permission':
      return <PermissionChip {...props} />;
    case 'queue_internal':
      return <QueueChip {...props} />;
    case 'rule': {
      // rules can exist as assets or actors, so we need to help here
      return <RuleChip {...props} type="rule" />;
    }
    case 'sar':
      return <SARChip {...props} />;
    case 'subdisposition':
    case 'subdisposition_option':
      return <SubdispositionChip {...props} />;
    case 'team':
      return <TeamChip {...props} />;
    case 'tag':
      return <TagChip {...props} />;
    case 'txn_event':
      return <TransactionEventChip {...props} />;
    case 'workflow_action':
      return <WorkflowButtonChip {...props} />;
    case 'risk_model':
      return <RiskModelChip {...props} />;
    case 'deadline':
    case 'fincen_ctr_batch':
    case 'investigation_checklist':
    case 'matchlist':
    case 'narrative':
    case 'narrative_template':
    case 'queue':
    case 'risk_score':
    case 'sar_template':
    case 'webhook':
    case 'system':
      return (
        <Unreachable error={`Unsupported audit log asset type: ${type}`} />
      );
    default:
      try {
        return assertUnreachable(type);
      } catch (error) {
        return (
          <Unreachable error={`Unexpected audit log asset type: ${type}`} />
        );
      }
  }
};

function assertUnreachable(x: never): never {
  throw new Error(`Unreachable value: ${x}`);
}

export const ActorChip = (actor: ActorChipProps) => {
  const { name, type } = actor;

  if (type === 'rule') {
    return <RuleChip {...actor} type="rule" />;
  }

  if (name === 'API') {
    return <APIChip />;
  }

  return <AgentChip {...actor} />;
};

const APIChip = () => <StyledChip icon={<IconRobot />}>API</StyledChip>;

export const ActionEventChip = (props: BasicChipProps) => {
  const { id, name, tooltip } = useMissingAwareProps(props);

  return (
    <StyledU21DataChip
      type={U21DataChipType.ACTION_EVENT}
      id={id}
      label={name}
      tooltip={tooltip}
    />
  );
};

// Unusual scenarios that might happen accidentally
// id == -1 for unassignment actions
// id == 0 for representing an "API Agent" (should get APIChip though)
export const AgentChip = (props: BasicChipProps | ActorChipProps) => {
  const sessionAgent = useSelector(selectAgent);
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermissions);
  const { id, name, tooltip, isMissing } = useMissingAwareProps(props);

  if (isMissing) {
    return (
      <StyledChip icon={<IconUserX />} tooltip={tooltip}>
        {name}
      </StyledChip>
    );
  }

  const getUrl = () => {
    if (typeof id === 'number' && id <= 0) return undefined;
    if (
      hasReadAgentsPermission ||
      id.toString() === sessionAgent.id.toString()
    ) {
      return ROUTES_MAP.agentsId.path.replace(':id', id.toString());
    }

    return undefined;
  };

  let nameToRender: string;
  if (id === -1) {
    nameToRender = 'No one';
  } else if (name === '') {
    nameToRender = `ID: ${id}`;
  } else {
    nameToRender = name;
  }

  return (
    <StyledChip to={getUrl()} icon={<IconUser />}>
      {nameToRender}
    </StyledChip>
  );
};

export const AlertChip = (props: BasicChipProps) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadAlertsPermission
          ? ROUTES_MAP.alertsId.path.replace(':id', String(id))
          : undefined
      }
      icon={<IconFlag />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

export const AttachmentChip = (props: BasicChipProps) => {
  const { name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip icon={<IconFileDescription />} tooltip={tooltip}>
      {name}
    </StyledChip>
  );
};

export const CaseChip = (props: BasicChipProps) => {
  const hasReadCasesPermission = useSelector(selectHasReadCasesPermissions);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadCasesPermission
          ? ROUTES_MAP.casesId.path.replace(':id', String(id))
          : undefined
      }
      icon={<IconBriefcase />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

// unhandled `deadline` type chip goes here

export const DispositionChip = (props: BasicChipProps) => {
  const { name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip icon={<IconMapPin />} tooltip={tooltip}>
      {name}
    </StyledChip>
  );
};

export const EntityChip = (props: BasicChipProps) => {
  const { id, name, tooltip } = useMissingAwareProps(props);

  return (
    <StyledU21DataChip
      type={U21DataChipType.ENTITY}
      id={id}
      label={name}
      tooltip={tooltip}
    />
  );
};

export const FincenCtrChip = (props: BasicChipProps) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadSARsPermission
          ? ROUTES_MAP.filingsFincenCtrEdit.path
              .replace(':id', String(id))
              .replace(':tab?', '')
          : undefined
      }
      icon={<IconCash />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

// unsupported `fincen_ctr_batch` chip goes here

export const GoAMLChip = (props: BasicChipProps) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadSARsPermission
          ? ROUTES_MAP.filingsGoAMLIdEdit.path.replace(':id', String(id))
          : undefined
      }
      icon={<IconReportMoney />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

export const InstrumentChip = (props: BasicChipProps) => {
  const { id, name, tooltip } = useMissingAwareProps(props);

  return (
    <StyledU21DataChip
      type={U21DataChipType.INSTRUMENT}
      id={id}
      label={name}
      tooltip={tooltip}
    />
  );
};

// unsupported `investigation_checklist` chip goes here

// unsupported `matchlist` chip goes here

// unsupported `narrative` chip goes here

// unsupported `narrative_template` chip goes here

export const PermissionChip = (props: BasicChipProps) => {
  const hasReadTeamsPermission = useSelector(selectHasReadTeamsPermission);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadTeamsPermission
          ? ROUTES_MAP.teamsIdTab.path
              .replace(':id', String(id))
              .replace(':tab?', 'permissions')
          : undefined
      }
      icon={<IconKey />}
      tooltip={tooltip}
    >
      {PERMISSION_NAMES[name] ?? name}
    </StyledChip>
  );
};

// Intentionally not using missing aware props, as it provides no additional insight
export const RiskModelChip = ({ id }: BasicChipProps) => {
  const hasReadRiskRatingsPermission = useSelector(
    selectHasReadRiskRatingsPermission,
  );
  return (
    <StyledChip
      to={
        hasReadRiskRatingsPermission
          ? ROUTES_MAP.riskRatingsId.path.replace(':id', String(id))
          : undefined
      }
    >
      Risk Model #{id}
    </StyledChip>
  );
};

// unsupported `risk_score` chip goes here

export const RuleChip = (props: AssetsChipProps) => {
  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);
  const { id, name, tooltip, isMissing } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadRulesPermission
          ? ROUTES_MAP.detectionModelsId.path.replace(':id', String(id))
          : undefined
      }
      icon={<IconScale />}
      tooltip={tooltip}
    >
      {isMissing ? name : `Rule: ${name}`}
    </StyledChip>
  );
};

export const SARChip = (props: BasicChipProps) => {
  const hasReadSARsPermission = useSelector(selectHasReadSARsPermissions);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadSARsPermission
          ? ROUTES_MAP.filingsSarId.path.replace(':id', String(id))
          : undefined
      }
      icon={<IconBuildingBank />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

// unsupported `sar_template` chip goes here

export const SubdispositionChip = (props: BasicChipProps) => {
  const { name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip icon={<IconMapPin />} tooltip={tooltip}>
      {name}
    </StyledChip>
  );
};

export const TagChip = (props: BasicChipProps) => {
  const { name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip icon={<IconTag />} tooltip={tooltip}>
      {name}
    </StyledChip>
  );
};

export const TeamChip = (props: BasicChipProps) => {
  const hasReadTeamsPermission = useSelector(selectHasReadTeamsPermission);
  const { id, name, tooltip } = useMissingAwareProps(props);
  return (
    <StyledChip
      to={
        hasReadTeamsPermission
          ? ROUTES_MAP.teamsId.path.replace(':id', id.toString())
          : undefined
      }
      icon={<IconUsers />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

export const TransactionEventChip = (props: BasicChipProps) => {
  const { id, name, tooltip } = useMissingAwareProps(props);

  return (
    <StyledU21DataChip
      type={U21DataChipType.TXN_EVENT}
      id={id}
      label={name}
      tooltip={tooltip}
    />
  );
};

// unsupported `webhook` chip goes here

export const WorkflowButtonChip = (props: BasicChipProps) => {
  const { name, tooltip } = useMissingAwareProps(props);
  return <StyledChip tooltip={tooltip}>{name}</StyledChip>;
};

// Non-standard asset chips

export const DeadlineTypeChip = ({ deadlineType }: DeadlineTypeChipProps) => {
  let text;
  switch (deadlineType) {
    case DeadlineType.ARTICLE_DEADLINE:
      text = 'Article deadline';
      break;
    case DeadlineType.MANUAL_DEADLINE:
      text = 'Manual deadline';
      break;
    case DeadlineType.QUEUE_DEADLINE:
      text = 'Queue deadline';
      break;
    default:
      text = 'N/A';
      break;
  }
  return <StyledChip icon={<IconCalendarTime />}>{text}</StyledChip>;
};

export const DateChip = ({ date }: DateChipProps) => {
  return (
    <StyledChip icon={<IconCalendar />}>{formatDatetime(date)}</StyledChip>
  );
};

export const QueueChip = (props: QueueChipProps) => {
  const hasEditQueuesPermission = useSelector(selectHasEditQueuesPermission);
  const { queueType, id, name, tooltip } = useMissingAwareProps(props);
  const route = QUEUE_ATTRS[queueType].path;
  return (
    <StyledChip
      to={
        hasEditQueuesPermission ? route.replace(':id', String(id)) : undefined
      }
      icon={<IconClipboardList />}
      tooltip={tooltip}
    >
      {name}
    </StyledChip>
  );
};

const StyledChip = styled(U21Chip)`
  margin: 2px 2px;
`;

const StyledU21DataChip = styled(U21DataChip)`
  margin: 2px 2px;
`;
